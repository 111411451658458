import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Select,
  FormLabel,
  FormControl,
  Input,
  Text,
  Checkbox,
  Button,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { CreateRole, GetRole, UpdateRole } from "services/roleService";

export default function AddRole() {
  const location = useLocation();
  const navigate = useNavigate();
  const roleId = location.state?.roleId;

  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const userPriviledges = userState.hasOwnProperty("priviledges") ? userState.priviledges : [];
 
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const [loading, setLoading] = useState(false);
  const [priviledges, setPriviledges] = useState([]);
  const [district, setDistrict] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [role, setRole] = useState({
    roleName: "",
    roleAbbreviation: "",
    priviledges: ""
  });

  const handleAddRole = async () => {
    try {
      setLoading(true);
      /*if(!userPriviledges.includes("Admin")){
        toast({
          title: "You are not allowed",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }*/
      role.priviledges = JSON.stringify(priviledges);
      const response = roleId !== null && roleId !== undefined
        ? await UpdateRole(role, roleId, token)
        : await CreateRole(role, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      navigate("/admin/settings/roles");
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getRole = async () => {
    try {
      setLoading(true);
      const response = await GetRole(roleId, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setRole({
          roleName: response.data.roleName,
          roleAbbreviation: response.data.roleAbbreviation,
          priviledges: response.data.priviledges
        });
        const userPriviledges = response.data.priviledges !== null && response.data.priviledges !== "" ? JSON.parse(response.data.priviledges) : [];
        setPriviledges(userPriviledges);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const addPriviledge = async (priviledge) => {
    const updatedPriviledges = priviledges.concat(priviledge);
    setPriviledges(updatedPriviledges);
  };

  const removePriviledge = async (priviledge) => {
    const indexToRemove = priviledges.indexOf(priviledge);
    const updatedPriviledges = [...priviledges];
    if (indexToRemove !== -1) {
      updatedPriviledges.splice(indexToRemove, 1);
    }
    setPriviledges(updatedPriviledges);
  };

  const handleCheck = (priviledge, isChecked) => {
    if (isChecked) {
      addPriviledge(priviledge);
    } else {
      removePriviledge(priviledge);
    }
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allPriviledges = ['Admin', 'Manage Dispute','Manage Trade','Manage User', 'Manage Payment', 'View Report', 'Manage Roles', 'Manage Tickets']
      setPriviledges(allPriviledges);
    } else {
      setPriviledges([]);
    }
  };

  useEffect(() => {
    if (roleId !== null && roleId !== undefined) {
      getRole();
    }
  }, []);


  return (
    <>
      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%" mb="20">
        <SimpleGrid
          width="100%"
          columns={{ base: 2, md: 2 }}
          spacing="2"
          mb="25"
          mt="25"
        >
          <Box mb="5">
            {" "}
            <Text
              color={"white"}
              fontSize="18px"
              mb="4px"
              fontWeight="600"
              lineHeight="100%"
            >
              {roleId !== null && roleId !== undefined
                ? "Update Role"
                : "Add Role"}
            </Text>
          </Box>

          <Box width="100%" textAlign="right">
            <Button
              colorScheme="teal"
              background="secondary.500"
              size="sm"
              onClick={() => {
                navigate("/app/role/index");
              }}
            >
              Roles
            </Button>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="2"
          mb="3"
        >
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Role Name</FormLabel>
              <Input
                borderRadius="5"
                 color="white"
                h="8"
                placeholder="Role Name"
                defaultValue={role.roleName}
                onChange={(e) => {
                  setRole({ ...role, roleName: e.target.value });
                }}
              />
            </FormControl>
          </Box>

          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Role Abbreviation</FormLabel>
              <Input
                borderRadius="5"
                color="white"
                h="8"
                placeholder="Role Abbreviation"
                defaultValue={role.roleAbbreviation}
                onChange={(e) => {
                  setRole({ ...role, roleAbbreviation: e.target.value });
                }}
              />
            </FormControl>
          </Box>
        </SimpleGrid>


        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="2"
          mt="2"
        >
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Priviledges</FormLabel>

              <Box width="100%">
                <Text fontWeight="bold">Admin</Text>
                <Flex>
                  <Checkbox
                    isChecked={priviledges.indexOf("Admin") !== -1}
                    onChange={(e) => {
                      handleCheck("Admin", e.target.checked);
                      handleSelectAll(e.target.checked);
                    }}
                  />
                  &nbsp;
                  <Text>All Priviledges</Text>
                </Flex>
              </Box>

              <Box width="100%" mt="3">
                <Text fontWeight="bold">Dispute</Text>
                <Flex>
                  <Checkbox
                    isChecked={priviledges.indexOf("Manage Dispute") !== -1}
                    onChange={(e) =>
                      handleCheck("Manage Dispute", e.target.checked)
                    }
                  />
                  &nbsp;
                  <Text>Manage Dispute</Text>
                </Flex>
              </Box>

              <Box width="100%" mt="3">
                <Text fontWeight="bold">Manage Trade</Text>
                <Flex>
                  <Checkbox
                    isChecked={priviledges.indexOf("Manage Trade") !== -1}
                    onChange={(e) =>
                      handleCheck("Manage Trade", e.target.checked)
                    }
                  />
                  &nbsp;
                  <Text>Manage Trade</Text>
                </Flex>
              </Box>

              <Box width="100%" mt="3">
                <Text fontWeight="bold">Manage User</Text>
                <Flex>
                  <Checkbox
                    isChecked={priviledges.indexOf("Manage User") !== -1}
                    onChange={(e) =>
                      handleCheck("Manage User", e.target.checked)
                    }
                  />
                  &nbsp;
                  <Text>Manage User</Text>
                </Flex>
              </Box>

              <Box width="100%" mt="3">
                <Text fontWeight="bold">Reports</Text>
                <Flex>
                  <Checkbox
                    isChecked={priviledges.indexOf("View Report") !== -1}
                    onChange={(e) =>
                      handleCheck("View Report", e.target.checked)
                    }
                  />
                  &nbsp;
                  <Text>View Report</Text>
                </Flex>
              </Box>

              <Box width="100%" mt="3">
                <Text fontWeight="bold">Manage Roles</Text>
                <Flex>
                  <Checkbox
                    isChecked={priviledges.indexOf("Manage Roles") !== -1}
                    onChange={(e) =>
                      handleCheck("Manage Roles", e.target.checked)
                    }
                  />
                  &nbsp;
                  <Text>Manage Roles</Text>
                </Flex>

                <Flex>
                  <Checkbox
                    isChecked={
                      priviledges.indexOf("Manage Tickets") !== -1
                    }
                    onChange={(e) =>
                      handleCheck("Manage Tickets", e.target.checked)
                    }
                  />
                  &nbsp;
                  <Text>Manage Tickets</Text>
                </Flex>
                <Flex>
                  <Checkbox
                    isChecked={priviledges.indexOf("Manage Payment") !== -1}
                    onChange={(e) =>
                      handleCheck("Manage Payment", e.target.checked)
                    }
                  />
                  &nbsp;
                  <Text>Manage Payment</Text>
                </Flex>
              </Box>
            </FormControl>
          </Box>
        </SimpleGrid>

        <Box textAlign="left" mt="3" width="100%">
          <Button
            width="150px"
            height="30px"
            colorScheme="brand"
            background="white"
            isLoading={loading}
            loadingText="Wait..."
            onClick={handleAddRole}
          >
            {roleId !== null && roleId !== undefined ? "Update" : "Create"}
          </Button>
        </Box>
      </Box>
    </>
  );
}
