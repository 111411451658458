import React, { useState, useEffect } from "react";
import {
    Box,
    Heading,
    Text,
    Tag,
    Avatar,
    Center,
    VStack,
    HStack,
    Button,
    Spinner,
    SimpleGrid,
    Flex,
    Icon,
    useClipboard
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Card from "components/card/Card";
import Timer from "./Timer";
import { FiEye, FiCopy, FiMessageCircle } from "react-icons/fi";
import { useSelector } from "react-redux";
import NETWORKS from "contracts/Networks.json";
const { DateTime } = require("luxon");

export default function TradeInfo(props) {
    const [value, setValue] = useState('');
    const { onCopy, hasCopied } = useClipboard(value);
    const userState = useSelector((state) => state?.user?.value);
    const userId = userState.hasOwnProperty("userId") ? userState.userId : null;
    const { trade, disputed, unreadCount, handleTimeOut, setView, onCancelOpen, onTransferAssetOpen, onTransferFiatOpen, cancelLoading, transferLoading } = props;

    function formatRelativeDate(dateString) {
        const date = new Date(dateString);
        const diff = Date.now() - date.getTime();
        const minutes = Math.floor(diff / (1000 * 60));
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));

        if (minutes < 60) {
            return `${minutes} minutes ago`;
        } else if (hours < 24) {
            return `${hours} hours ago`;
        } else if (days < 30) {
            return `${days} days ago`;
        } else if (days < 365) {
            return `${Math.floor(days / 30)} months ago`;
        } else {
            return `${Math.floor(days / 365)} years ago`;
        }
    }

    function checkPaymentTimeOut(paymentDateString) {
        // Parse the payment date string into a Date object
        const paymentDate = new Date(paymentDateString);
        // Get the current date and time
        const currentDate = new Date();
        // Check if the current date and time is greater than the payment date
        if (currentDate > paymentDate) {
            return true;
        } else {
            return false;
        }
    }

    function getTimeStamp(timeString) {
        if (timeString !== null) {
            const date = new Date(timeString);
            return date.getTime();
        }
    }

    useEffect(() => {
        if (value === "") return;
        onCopy();
    }, [value]);

    return (
        <>
            <VStack width="100%" spacing="3">
                <Box width="100%" textAlign="center">
                    <Tag background="brand.500" color="white"> {trade.type.toUpperCase()} {trade.advert.asset.symbol}</Tag>
                </Box>
                <Box textAlign="center">
                    <Heading fontSize={{ sm: "lg", md: "2xl" }} color="white"> {trade.unit["$numberDecimal"]} {trade.advert.asset.symbol}</Heading>
                    &nbsp;
                    {trade.advert.network !== null && <Avatar src={NETWORKS[trade.advert.network]['ICONURL']} size="sm" />}
                </Box>
            </VStack>
            <Center mt="5">
                <Card background="brandScheme.200" borderColor="brand.500" width={{ sm: "100%", md: "70%", lg: "50%" }} minH="300">
                    <VStack width="100%" spacing="6">
                        <Box width="100%">
                            <Flex width="100%" justifyContent={"space-between"}>
                                <Box textAlign={"left"}><Text color="#ffffff" fontSize={{ sm: "lg", md: "xl" }} fontWeight="bold">Time Left</Text></Box>
                                <Box textAlign={"right"}><Timer time={getTimeStamp(trade.paymentGrace)} handler={handleTimeOut} /></Box>
                            </Flex>
                        </Box>

                        <Box width="100%">
                            <Flex width="100%" justifyContent={"space-between"}>
                                <Box textAlign={"left"}><Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }} fontWeight="bold">Status</Text></Box>
                                <Box textAlign={"right"}><Tag background={trade.status === "ended" ? "secondary.500" : (trade.status === "cancelled" ? "orange.500" : "brand.500")} color="white">{trade.status}</Tag></Box>
                            </Flex>
                        </Box>

                        <Box width="100%">
                            <Flex width="100%" justifyContent={"space-between"}>
                                <Box textAlign={"left"}><Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }} fontWeight="bold">Trade Reference</Text></Box>
                                <Box textAlign={"right"}><Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }}>#{trade._id}</Text></Box>
                            </Flex>
                        </Box>

                        {
                            trade.funded &&
                            <Box width="100%">
                                <Card background="brand.200" borderColor="brand.500" borderWidth="1px" width="100%" minH="50">
                                    <Text color="white">
                                        {(() => {
                                            if (trade.type === "sell" && trade.seller._id === userId) {
                                                return ("The following is your payment info. Please make sure the money is transferred and confirm the sender name (" + trade.buyer.firstName + " " + trade.buyer.lastName + ") for your safety. Money will not be transferred automatically by the platform")
                                            }
                                            else if (trade.type === "sell" && trade.advert.owner._id === userId) {
                                                return ("The following is the seller's payment info. Please make sure the money is transferred from an amount you own. Money will not be transferred automatically by the platform")
                                            }
                                            if (trade.type === "buy" && trade.buyer._id === userId) {
                                                return ("The following is the seller's payment info. Please make sure the money is transferred from an amount you own. Money will not be transferred automatically by the platform")
                                            }
                                            else if (trade.type === "buy" && trade.advert.owner._id === userId) {
                                                return ("The following is your payment info. Please make sure the money is transferred and confirm the sender name (" + trade.buyer.firstName + " " + trade.buyer.lastName + ") for your safety. Money will not be transferred automatically by the platform")
                                            }
                                        })()}
                                    </Text>
                                </Card>
                            </Box>
                        }

                        <Box width="100%">
                            <Flex width="100%" justifyContent={"space-between"}>
                                <Box textAlign={"left"}><Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }} fontWeight="bold">Amount</Text></Box>
                                <Box textAlign={"right"}>
                                    <Flex color="white" gap="2">
                                        <Text color="white" fontSize={{ sm: "md", md: "lg" }}>{trade.advert?.currency?.symbol}&nbsp;{new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(trade.price["$numberDecimal"])}</Text>
                                        <Icon mt="2" as={FiCopy} cursor="pointer"
                                            onClick={() => {
                                                setValue(trade.price["$numberDecimal"]);
                                            }} />
                                        <Text color="white">{value === trade.price["$numberDecimal"] && hasCopied ? 'Copied!' : ''}</Text>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>

                        <Box width="100%">
                            <Flex width="100%" justifyContent={"space-between"}>
                                <Box textAlign={"left"}><Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }} fontWeight="bold">Payment Method</Text></Box>
                                <Box textAlign={"right"}>
                                    <Tag mt="2" size="sm" background="brand.500" color="white" fontWeight="bold">{trade.type === 'sell' ? trade.advert.paymentMethod.name : trade.advert.paymentMethod.paymentMethod.name} </Tag>
                                </Box>
                            </Flex>
                        </Box>

                        <Box width="100%">
                            <Flex width="100%" justifyContent={"space-between"}>
                                <Box textAlign={"left"}><Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }} fontWeight="bold">Payment Status</Text></Box>
                                <Box textAlign={"right"}>
                                    <Tag mt="2" size="sm" background={trade.paid === true ? "green.300" : "orange.300"} color="white" fontWeight="bold">{trade.paid === true ? "Mark as Paid" : "Pending Payment"} </Tag>
                                </Box>
                            </Flex>
                        </Box>

                        {
                            trade.funded &&
                            <Box width="100%">
                                <Flex width="100%" justifyContent={"space-between"}>
                                    <Box textAlign={"left"}><Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }} fontWeight="bold">Payment Details</Text></Box>
                                    <Box textAlign={"right"}>

                                        {
                                            trade.type === 'buy' && trade.advert.paymentMethod.details &&
                                            (() => {
                                                let det = JSON.parse(trade.advert.paymentMethod.details);
                                                return (
                                                    <>
                                                        <Text color="white">
                                                            {
                                                                Object.entries(det).map(([key, details]) => {
                                                                    return (
                                                                        <HStack key={key} spacing={2} mb="2">
                                                                            <Text>{key}:</Text>
                                                                            <Text>{details}</Text>
                                                                            <Icon as={FiCopy} cursor="pointer"
                                                                                onClick={() => {
                                                                                    setValue(details);
                                                                                }} />
                                                                            <Text>{value === details && hasCopied ? 'Copied!' : ''}</Text>
                                                                        </HStack>
                                                                    )
                                                                })
                                                            }
                                                        </Text>
                                                    </>
                                                )
                                            })()
                                        }


                                        {
                                            trade.type === 'sell' && trade.paymentMethod.details &&
                                            (() => {
                                                let det = JSON.parse(trade.paymentMethod.details);
                                                return (
                                                    <>
                                                        <Text color="white">
                                                            {
                                                                Object.entries(det).map(([key, details]) => {
                                                                    return (
                                                                        <HStack key={key} spacing={2} mb="2">
                                                                            <Text>{key}:</Text>
                                                                            <Text>{details}</Text>
                                                                            <Icon as={FiCopy} cursor="pointer"
                                                                                onClick={() => {
                                                                                    setValue(details);
                                                                                }} />
                                                                            <Text>{value === details && hasCopied ? 'Copied' : ''}</Text>
                                                                        </HStack>
                                                                    )
                                                                })
                                                            }
                                                        </Text>
                                                    </>
                                                )
                                            })()
                                        }

                                    </Box>
                                </Flex>
                            </Box>
                        }

                        <Box width="100%" borderTopWidth="1px" borderTopColor={"brand.500"} padding="2">
                            <Flex width="100%" justifyContent={"space-between"}>
                                <Box textAlign={"left"}><Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }} fontWeight="bold">Trading With</Text></Box>
                                <Box textAlign={"right"}>
                                    <Link to={"/app/user/" + (trade.seller._id === userId ? trade.buyer.username : trade.seller.username)}>
                                        <Text textDecoration="underline" color="#ffffff" fontSize={{ sm: "md", md: "lg" }}>{trade.seller._id === userId ? trade.buyer.username : trade.seller.username}</Text>
                                    </Link>
                                </Box>
                            </Flex>
                        </Box>

                        {trade.seller._id === userId &&
                            <Box width="100%" borderTopWidth="1px" borderTopColor={"brand.500"} padding="2">
                                <Flex width="100%" justifyContent={"space-between"}>
                                    <Box textAlign={"left"}><Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }} fontWeight="bold">Trading With</Text></Box>
                                    <Box textAlign={"right"}>
                                        <Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }}>{trade.buyer.firstName} {trade.buyer.lastName}</Text>
                                    </Box>
                                </Flex>
                            </Box>
                        }

                        <Box width="100%" padding="2">
                            <Flex width="100%" justifyContent={"space-between"}>
                                <Box textAlign={"left"}><Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }}>Last Seen</Text></Box>
                                <Box textAlign={"right"}>
                                    <Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }}>
                                        {
                                            trade.seller._id === userId ? formatRelativeDate(trade?.buyer?.lastSeen) : formatRelativeDate(trade?.seller?.lastSeen)
                                        }
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>

                        <Box width="100%" padding="2">
                            <Button mt="2" size="lg" colorScheme='brand' variant='outline' width="100%" onClick={() => { setView("chat") }}>
                                <Icon as={FiMessageCircle} cursor="pointer" color="white" />&nbsp;<Text color="white">Chat</Text>&nbsp;<Tag borderRadius='full' size={"md"} variant='solid' colorScheme='secondary'>{unreadCount}</Tag></Button>
                        </Box>
                    </VStack>
                </Card>
            </Center>

            {
                trade.funded && !trade.settled &&
                <Center mt="4">
                    <Card background="brand.100" borderColor="brand.500" borderWidth="1px" width={{ sm: "100%", md: "70%", lg: "50%" }} minH="100">
                        <Text color="white" fontSize={{ sm: "md", md: "lg" }}>
                            {(() => {
                                if (trade.type === "sell" && trade.seller._id === userId) {
                                    return ("Please confirm that you have successfully received transfer of the amount from the buyer through the following payment method")
                                }
                                else if (trade.type === "sell" && trade.advert.owner._id === userId) {
                                    return ("Please confirm that you have successfully transferred the money to the seller through the following payment method")
                                }
                                if (trade.type === "buy" && trade.buyer._id === userId && trade.paid === false) {
                                    return ("Please confirm that you have successfully transferred the money to the seller through the following payment method")
                                }
                                else if (trade.type === "buy" && trade.buyer._id === userId && trade.paid === true) {
                                    return ("Please wait for the seller to confirm payment and release asset")
                                }
                                else if (trade.type === "buy" && trade.advert.owner._id === userId && trade.paid === false) {
                                    return ("Please wait for buyer to complete payment")
                                }
                                else if (trade.type === "buy" && trade.advert.owner._id === userId && trade.paid === true) {
                                    return ("Please confirm that you have successfully received transfer of the amount from the buyer through the following payment method")
                                }
                            })()}
                        </Text>
                    </Card>
                </Center>
            }

            {
                trade.funded &&
                <Center mt="4" mb="10">
                    <Box width={{ sm: "100%", md: "70%", lg: "50%" }}>
                        <SimpleGrid width="100%" columns="2" spacing="2">
                            <Box>
                                {(() => {
                                    if (trade.type === "sell" && !trade.settled && (!trade.paid || trade.paid) && trade.seller._id === userId) {
                                        return (
                                            <Button width="100%" colorScheme="secondary" variant="outline" onClick={onTransferAssetOpen} isLoading={transferLoading} loadingText="Releasing...">Release Asset</Button>
                                        )
                                    }
                                    if (trade.type === "sell" && !trade.settled && !trade.paid && !trade.disputed && trade.advert.owner._id === userId) {
                                        return (
                                            <Button width="100%" colorScheme="secondary" variant="outline" onClick={onTransferFiatOpen}>Transferred, Next</Button>
                                        )
                                    }
                                    if (trade.type === "buy" && !trade.settled && (!trade.paid || trade.paid) && trade.advert.owner._id === userId) {
                                        return (
                                            <Button width="100%" colorScheme="secondary" variant="outline" onClick={onTransferAssetOpen} isLoading={transferLoading} loadingText="Releasing...">Release Asset</Button>
                                        )
                                    }
                                    if (trade.type === "buy" && !trade.settled && !trade.paid && !trade.disputed && trade.buyer._id === userId) {
                                        return (
                                            <Button width="100%" colorScheme="secondary" variant="outline" onClick={onTransferFiatOpen}>Transferred, Next</Button>
                                        )
                                    }
                                })()}

                            </Box>
                            <Box>
                                {(() => {
                                    if (trade.type === "sell" && !trade.settled && !trade.paid && trade.advert.owner._id === userId) {
                                        return (
                                            <Button width="100%" colorScheme="orange" variant="outline" onClick={onCancelOpen} isLoading={cancelLoading} color="white" _hover={{ color: "#00000" }}> Cancel </Button>
                                        )
                                    }
                                    if (trade.type === "sell" && !trade.settled && trade.paid && trade.advert.owner._id === userId) {
                                        return (
                                            <Button width="100%" colorScheme="orange" variant="outline" onClick={onCancelOpen} isLoading={cancelLoading} color="white" _hover={{ color: "#00000" }}>Cancel</Button>
                                        )
                                    }
                                    if (trade.type === "buy" && !trade.settled && !trade.paid && !trade.disputed && trade.buyer._id === userId) {
                                        return (
                                            <Button width="100%" colorScheme="orange" variant="outline" onClick={onCancelOpen} isLoading={cancelLoading} color="white" _hover={{ color: "#00000" }}>Cancel</Button>
                                        )
                                    }
                                    if (trade.type === "buy" && !trade.settled && trade.paid && trade.buyer._id === userId) {
                                        return (
                                            <Button width="100%" colorScheme="orange" variant="outline" onClick={onCancelOpen} isLoading={cancelLoading} color="white" _hover={{ color: "#00000" }}>Cancel</Button>
                                        )
                                    }
                                })()}
                            </Box>
                        </SimpleGrid>
                        {
                            !trade.settled && !trade.disputed && checkPaymentTimeOut() &&
                            <Box mt="2" width="100%">
                                <Button width="100%" colorScheme="secondary" variant="outline" onClick={onTransferAssetOpen}>Open Dispute</Button>
                            </Box>
                        }
                    </Box>
                </Center>
            }

            {
                !trade.funded && !trade.settled &&
                <Center mt="4">
                    <Card background="brand.100" borderColor="brand.500" borderWidth="1px" width={{ sm: "100%", md: "70%", lg: "50%" }} minH="50">
                        <Flex justifyContent={"space-between"}>
                            <Spinner color="white" />
                            <Text color="white" fontSize={{ sm: "md", md: "lg" }}>
                                Please wait for trade to be funded...
                            </Text>
                            <Spinner color="white" />
                        </Flex>
                    </Card>
                </Center>
            }

        </>
    );
};
