import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
} from "@chakra-ui/react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import Pattern from "assets/images/bg-line1.svg";
function FAQs() {
  return (
    <Container
      maxW={"100%"}
      p={10}
      background="#04040e"
      backgroundImage={`url(${Pattern})`}
      backgroundPosition="center center"
      backgroundSize="100% auto"
      backgroundRepeat="no-repeat"
    >
      <div
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      zIndex="-1"
    > </div>
      <SimpleGrid width="100%" columns={{ sm: 1, md: 2 }}>
        <Box textAlign={"center"}>
          <Center width="100%" height="100%">
            <Heading
              as="h3"
              fontSize={{ sm: "lg", md: "2xl" }}
              fontFamily="Spantran"
              fontWeight="400"
              textAlign={"center"}
              color="white">
              Frequently Asked<Text color="secondary.500">Questions ?</Text>
            </Heading>
        </Center>
        </Box>
        <Box>
          <Accordion allowMultiple>
            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon
                          as={AiOutlineMinus}
                          boxSize={6}
                          color="secondary.500"
                        />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} color="white" />
                      )}
                      <Box flex="1" textAlign="left" ml="2" mt="3" mb="0.7rem">
                        <Text
                          fontSize={{ sm: "md", md: "lg" }}
                          fontFamily="ManropeSemiBold"
                          fontWeight="bold"
                          color="#ffffff"
                        >
                          {" "}
                          What is Scrowed?{" "}
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                     fontSize={{ sm: "md", md: "lg" }}
                     fontFamily="ManropeLight"
                      color="#ffffff"
                    >
                      Scrowed is a decentralized peer-to-peer (P2P) platform that allows users to buy and sell cryptocurrencies with fiat currency. It uses smart contract technology to handle the escrow process, ensuring secure and trustworthy transactions.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon
                          as={AiOutlineMinus}
                          boxSize={6}
                          color="secondary.500"
                        />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} color="white" />
                      )}
                      <Box
                        flex="1"
                        ml="2"
                        mt="3"
                        textAlign="left"
                        color="text.100"
                        mb="0.7rem"
                      >
                        <Text
                          fontSize={{ sm: "md", md: "lg" }}
                          fontFamily="ManropeSemiBold"
                          color="#ffffff"
                        >
                          {" "}
                          How does the escrow system work?
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    fontSize="1.6rem"
                    lineHeight="2.6rem"
                    color="text.400"
                  >
                    <Text
                      fontSize={{ sm: "md", md: "lg" }}
                      fontFamily="ManropeLight"
                      color="#ffffff"
                    >
                      When you initiate a transaction on Scrowed, the funds are held in a decentralized smart contract escrow. The funds are only released once both parties have fulfilled their end of the deal—ensuring that both buyers and sellers are protected from fraud.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon
                          as={AiOutlineMinus}
                          boxSize={6}
                          color="secondary.500"
                        />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} color="white" />
                      )}
                      <Box
                        flex="1"
                        ml="2"
                        mt="3"
                        textAlign="left"
                        color="text.100"
                        mb="0.7rem"
                      >
                        <Text
                          fontSize={{ sm: "md", md: "lg" }}
                          fontFamily="ManropeSemiBold"
                          color="#ffffff"
                        >
                          {" "}
                          What cryptocurrencies and fiat currencies are supported?
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    fontSize="1.6rem"
                    lineHeight="2.6rem"
                    color="text.400"
                  >
                    <Text
                      fontSize={{ sm: "md", md: "lg" }}
                      fontFamily="ManropeLight"
                      color="#ffffff"
                    >
                      Scrowed supports a variety of cryptocurrencies including Polygon, Base, Binance Chain, Arbitrum and Trias. You can buy or sell these cryptocurrencies using multiple fiat currencies depending on the preferences of the users involved in the transaction.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon
                          as={AiOutlineMinus}
                          boxSize={6}
                          color="secondary.500"
                        />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} color="white" />
                      )}
                      <Box
                        flex="1"
                        ml="2"
                        mt="3"
                        textAlign="left"
                        color="text.100"
                        mb="0.7rem"
                      >
                        {" "}
                        <Text
                          fontSize={{ sm: "md", md: "lg" }}
                          fontFamily="ManropeSemiBold"
                          color="white"
                        >
                          Is Scrowed safe to use?
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    fontSize="1.6rem"
                    lineHeight="2.6rem"
                    color="text.400"
                  >
                    <Text
                      fontSize={{ sm: "md", md: "lg" }}
                      fontFamily="ManropeLight"
                      color="white"
                    >
                     Yes, Scrowed employs a smart contract escrow to ensure the safety and security of all transactions. The smart contract escrow provides top-of-the-line secured trade by holding funds in a tamper-proof environment until both parties fulfill their obligations.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon
                          as={AiOutlineMinus}
                          boxSize={6}
                          color="secondary.500"
                        />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} color="white" />
                      )}
                      <Box
                        flex="1"
                        ml="2"
                        mt="3"
                        textAlign="left"
                        color="text.100"
                        mb="0.7rem"
                      >
                        {" "}
                        <Text
                          fontSize={{ sm: "md", md: "lg" }}
                          fontFamily="ManropeSemiBold"
                          color="#ffffff"
                        >
                          How do I get started on Scrowed?
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    fontSize="1.6rem"
                    lineHeight="2.6rem"
                    color="text.400"
                  >
                    <Text
                      fontSize={{ sm: "md", md: "lg" }}
                      fontFamily="ManropeLight"
                      color="#ffffff"
                    >
                      To get started, sign up on the Scrowed platform with your email address and create a secure password. Registration is as simple as connecting your Web3 wallet. Complete the KYC verification process to activate your account. Once verified, you can start buying or selling cryptocurrencies by following the simple steps provided on the platform.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon
                          as={AiOutlineMinus}
                          boxSize={6}
                          color="secondary.500"
                        />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} color="white" />
                      )}
                      <Box
                        flex="1"
                        ml="2"
                        mt="3"
                        textAlign="left"
                        color="text.100"
                        mb="0.7rem"
                      >
                        {" "}
                        <Text
                          fontSize={{ sm: "md", md: "lg" }}
                          fontFamily="ManropeSemiBold"
                          color="#ffffff"
                        >
                          What should I do if I encounter an issue during a transaction?
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    fontSize="1.6rem"
                    lineHeight="2.6rem"
                    color="text.400"
                  >
                    <Text
                      fontSize={{ sm: "md", md: "lg" }}
                      fontFamily="ManropeLight"
                      color="#ffffff"
                    >
                     If you encounter any issues during a transaction, Scrowed provides robust customer support to assist you. You can reach out to our support team through the contact options available on the platform. Additionally, the platform's smart contract escrow system ensures that your funds are safe and disputes can be resolved efficiently.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        </Box>
      </SimpleGrid>
    </Container>
  );
}

export default FAQs;
