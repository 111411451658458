import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  FormControl,
  Button,
  SimpleGrid,
  FormLabel,
  Input,
  VStack,
  Stack,
  Skeleton,
  SkeletonText,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import { GetAssets, GetCurrencies, GetPaymentMethods } from "services/miscService";
import { GetUserPaymentMethods, CreateUserPaymentMethod, GetTokenBalance } from "services/userService";
import CreateBuyAd from "./components/CreateBuyAd.js";
import CreateSellAd from "./components/CreateSellAd.js";
import { CreateBuyAdvert, CreateSellAdvert, UpdateSellAdvert, UpdateBuyAdvert, GetUserSellAd, GetUserBuyAd } from "services/adService";
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { useSelector } from "react-redux";
import NETWORKS from "contracts/Networks.json";

export default function Update() {
  const navigate = useNavigate();
  const { isOpen: isCreateUserPaymentMethodOpen, onOpen: onCreateUserPaymentMethodOpen, onClose: onCreateUserPaymentMethodClose } = useDisclosure();
  const parseBool = (str) => {
    return str.toLowerCase() === 'true';
  };

  const { type, adId } = useParams();

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const { walletProvider } = useWeb3ModalProvider();
  const { isConnected, address, chainId } = useWeb3ModalAccount();

  const toast = useToast();
  const [step, setStep] = useState("");
  const [buyStep, setBuyStep] = useState(1);
  const [sellStep, setSellStep] = useState(1);
  const [adLoading, setAdLoading] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);
  const [sellLoading, setSellLoading] = useState(false);

  const [sellAd, setSellAd] = useState({});
  const [buyAd, setBuyAd] = useState({});

  const [currencies, setCurrencies] = useState(null);
  const [assets, setAssets] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [userPaymentMethods, setUserPaymentMethods] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleUpdateBuyAd = async () => {
    try {
      setBuyLoading(true);
      let buyAdData = { ...buyAd };
      buyAdData['asset'] = (assets.find((a) => a.symbol == buyAd.asset))?._id;
      buyAdData['paymentMethod'] = (paymentMethods.find((m) => m._id == buyAd.paymentMethod))?._id;
      buyAdData['currency'] = (currencies.find((c) => c.symbol == buyAd.currency))?._id;
      buyAdData['conditions'] = { kycVerified: parseBool(buyAd.conditions.kycVerified) }
      buyAdData['balance'] = buyAd.totalAsset;
      buyAdData['unit'] = buyAd.totalAsset;
      const response = await UpdateBuyAdvert(buyAdData, adId, token);
      setBuyLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        navigate("/app/ads");
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setBuyLoading(false);
    }
  };

  const handleUpdateSellAd = async () => {
    try {
      setSellLoading(true);
      let sellAdData = { ...sellAd };
      sellAdData['asset'] = (assets.find((a) => a.symbol == sellAd.asset))?._id;
      sellAdData['currency'] = (currencies.find((c) => c.symbol == sellAd.currency))?._id;
      sellAdData['conditions'] = { kycVerified: parseBool(sellAd.conditions.kycVerified) }
      sellAdData['balance'] = sellAd.totalAsset;
      sellAdData['unit'] = sellAd.totalAsset;
      const response = await UpdateSellAdvert(sellAdData, adId, token);
      setSellLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        navigate("/app/ads");
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setSellLoading(false);
    }
  };

  const getSellAd = async () => {
    try {
      setAdLoading(true);
      const response = await GetUserSellAd(adId, token);
      setAdLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setSellAd({
          priceType: "floating",
          factor: response.data.factor["$numberDecimal"],
          asset: response.data.asset.symbol,
          currency: response.data.currency.symbol,
          currencyRate: response.data.currency.rateToDollar["$numberDecimal"],
          assetPrice: response.data.asset.rateToDollar["$numberDecimal"],
          price: response.data.price["$numberDecimal"],
          upperLimit: response.data.upperLimit,
          lowerLimit: response.data.lowerLimit,
          totalAsset: response.data.unit["$numberDecimal"],
          balance: response.data.balance,
          paymentMethod: response.data.paymentMethod._id,
          method: response.data.method,
          deadline: response.data.deadline,
          notes: response.data.notes,
          network: response.data.network,
          conditions: { kycVerified: !response.data.conditions?.kycVerified ? "false" : String(response.data.conditions?.kycVerified) }
        });
        handleGetBalance(response.data.asset.symbol);
        return;
      }
    } catch (error) {
      setAdLoading(false);
    }
  };

  const getBuyAd = async () => {
    try {
      setAdLoading(true);
      const response = await GetUserBuyAd(adId, token);
      setAdLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setBuyAd({
          priceType: "floating",
          factor: response.data.factor["$numberDecimal"],
          asset: response.data.asset.symbol,
          currency: response.data.currency.symbol,
          currencyRate: response.data.currency.rateToDollar["$numberDecimal"],
          assetPrice: response.data.asset.rateToDollar["$numberDecimal"],
          price: response.data.price["$numberDecimal"],
          upperLimit: response.data.upperLimit,
          lowerLimit: response.data.lowerLimit,
          totalAsset: response.data.unit["$numberDecimal"],
          balance: response.data.balance,
          paymentMethod: response.data.paymentMethod._id,
          deadline: response.data.deadline,
          notes: response.data.notes,
          network: response.data.network,
          conditions: { kycVerified: !response.data.conditions?.kycVerified ? "false" : String(response.data.conditions?.kycVerified) }
        });
        return;
      }
    } catch (error) {
      setAdLoading(false);
    }
  };

  const getCurrencies = async () => {
    try {
      setLoading(true);
      const response = await GetCurrencies();
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setCurrencies(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getAssets = async () => {
    try {
      setLoading(true);
      const response = await GetAssets();
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setAssets(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };


  const handleSelectChange = (e) => {
    const selectedMethod = paymentMethods.find(
      (method) => method._id === e.target.value
    );
    setCurrentMethod(selectedMethod);
    setCurrentMethodDetails(JSON.parse(selectedMethod.details));
  };

  const [currentMethod, setCurrentMethod] = useState(null);
  const [currentMethodDetails, setCurrentMethodDetails] = useState([]);
  const [paymentData, setPaymentData] = useState({});
  const [createPaymentLoading, setCreatePaymentLoading] = useState(false);
  const handleChange = (e, label) => {
    setPaymentData({
      ...paymentData,
      [label]: e.target.value,
    });
  };

  const handleCreatePaymentMethod = async () => {
    try {
      setCreatePaymentLoading(true);
      const userPaymentData = { ...paymentData, paymentMethod: currentMethod?._id }
      const response = await CreateUserPaymentMethod(userPaymentData, token);
      setCreatePaymentLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onCreateUserPaymentMethodClose();
        getUserPaymentMethods();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setCreatePaymentLoading(false);
    }
  };

  const getPaymentMethods = async () => {
    try {
      setLoading(true);
      const response = await GetPaymentMethods();
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setPaymentMethods(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getUserPaymentMethods = async () => {
    try {
      setLoading(true);
      const response = await GetUserPaymentMethods(token);
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setUserPaymentMethods(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [assetBalance, setAssetBalance] = useState(0);
  const handleGetBalance = async (asset) => {
    const walletConnect = { isConnected, chainId, address, walletProvider };
    if (chainId !== undefined && asset !== undefined) {
      const network = type === "sell" ? sellAd.network : buyAd.network;
      const balance = await GetTokenBalance(walletConnect, NETWORKS[network][asset], address);
      setAssetBalance(balance);
    }
  }

  useEffect(() => {
    getAssets();
    getCurrencies();
  }, []);

  useEffect(() => {
    if (currencies !== null && assets !== null) {
      getPaymentMethods();
    }
  }, [currencies, assets]);

  useEffect(() => {
    if (token !== null) {
      getUserPaymentMethods();
      if (type === "buy") { getBuyAd(); } else if (type === "sell") { getSellAd(); }
    }
  }, [token]);

  useEffect(() => {
    if (type === "sell") {
      handleGetBalance(sellAd.asset);
    }
  }, [chainId, sellAd.asset]);

  return (
    <>

      <Modal isOpen={isCreateUserPaymentMethodOpen} onClose={onCreateUserPaymentMethodClose} size={'lg'}>
        <ModalOverlay />
        <ModalContent background="brand.500">
          <ModalHeader color="white">Create Payment Method</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <FormControl>
              <FormLabel color={'white'} fontWeight="bold">
                Payment Option
              </FormLabel>
              <Select
                color="white"
                placeholder="Select Payment Option"
                onChange={handleSelectChange}
              >
                {paymentMethods !== null && paymentMethods.map((method) => <option key={method._id} value={method._id}>{method.name}</option>)}
              </Select>
            </FormControl>

            {currentMethodDetails.map((detail) => (
              <FormControl key={detail.label} mb={2} mt="4">
                <FormLabel color="white" fontWeight="bold">
                  {detail.label}
                </FormLabel>
                <Input
                  color="white"
                  maxLength={100}
                  type={detail.type === 'number' ? 'number' : 'text'}
                  placeholder={detail.instruction}
                  onChange={(e) => handleChange(e, detail.label)}
                />
              </FormControl>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onCreateUserPaymentMethodClose}>
              Cancel
            </Button>
            <Button
              background="secondary.500"
              color="white"
              _hover={{ color: "#000000", background: "#ffffff" }}
              isLoading={createPaymentLoading} loadingText="Wait.." onClick={handleCreatePaymentMethod}>Add</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} p="3" width="100%">

        <Box width="100%" mt="5" mb="5">
          <Heading color="white" size="md">
            Edit Ad
          </Heading>
        </Box>

        <Box width="100%" textAlign="right">
          <Button
            isLoading={loading}
            loadingText="Please Wait"
            onClick={() => { setStep("select") }}
          >
            Back
          </Button>
        </Box>
        {
          type === "sell" && sellAd.asset !== null && sellAd.currency !== null && <CreateSellAd setSellStep={setSellStep} sellStep={sellStep} sellLoading={sellLoading} sellAd={sellAd} setSellAd={setSellAd} currencies={currencies} assets={assets} userPaymentMethods={userPaymentMethods} handleCreateSellAd={handleUpdateSellAd} actionType={"Edit"} assetBalance={assetBalance} onCreateUserPaymentMethodOpen={onCreateUserPaymentMethodOpen} chainId={chainId} />
        }
        {
          type === "buy" && buyAd.asset !== null && buyAd.currency !== null && <CreateBuyAd setBuyStep={setBuyStep} buyStep={buyStep} buyLoading={buyLoading} buyAd={buyAd} setBuyAd={setBuyAd} currencies={currencies} assets={assets} paymentMethods={paymentMethods} handleCreateBuyAd={handleUpdateBuyAd} actionType={"Edit"} />
        }

        {adLoading && <LoadingAds />}
      </Box>
    </>
  );
}

const LoadingAds = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="10"
        mb="20"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};
