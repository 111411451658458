import { ethers, BrowserProvider } from "ethers";
import axios from "axios";
import axiosInstance from './axiosInstance'; // Adjust the path accordingly
import TOKENABI from "../contracts/IBEP20.json";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const UserLogin = async (address) => {
  try {
    const response = await axios.post(SERVER_URL + "/auth/login", { address }, {
      headers: {
        Authorization: `Bearer `,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUser = async token => {
  try {
    const response = await axiosInstance.get(SERVER_URL + '/user/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserPaymentMethods = async token => {
  try {
    const response = await axiosInstance.get(SERVER_URL + '/payment/usermethods', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CreateUserPaymentMethod = async (paymentData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/payment/usermethod", paymentData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateUserPaymentMethod = async (paymentData, methodId, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + "/payment/usermethod/"+methodId, paymentData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateUser = async (userData, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + '/user', userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const UpdateUserById = async (userData, userId, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + '/user/'+userId, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserRatings = async (pagination, filter, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL +
      "/rating/user/" + filter.owner + "?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetTokenBalance = async (walletConnect, TOKEN_ADDRESS, address) => {
  try {
    if (!walletConnect || !walletConnect.isConnected)
      throw new Error("Wallet not connected");
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);

    const signer = await ethersProvider.getSigner();
    // The Contract object
    const TokenContract = new ethers.Contract(
      TOKEN_ADDRESS,
      TOKENABI.abi,
      signer
    );
    const TokenBalance = await TokenContract.balanceOf(address);
    const balance = ethers.formatUnits(TokenBalance, 18);
    return balance;
  } catch (error) {
    return 0;
  }
};

const VerifyEmail = async (userData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/user/verifyEmail", userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const ResendEmailVerification = async (token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/user/resendEmailVerification", {}, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const InitiateKYC = async (userData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/user/initiateKYC", userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserByUsername = async (username, token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + '/user/' + username, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const CreateUser = async (userData, token) => {
  try {
    const response = await axios.post(SERVER_URL + "/user", userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const GetUserByID = async (userId, token) => {
  try {
    const response = await axios.get(SERVER_URL + "/user/id/" + userId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUsers = async (pagination, filter, token) => {
  try {
    const response = await axios.post(
      SERVER_URL +
        "/user/getUsers?from=" +
        pagination.from +
        "&limit=" +
        pagination.limit +
        "&orderBy=" +
        pagination.orderBy +
        "&sortOrder=" +
        pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const SearchUser = async (searchData, token) => {
  try {
    const response = await axios.post(SERVER_URL + "/user/search", searchData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const DeleteUser = async (userId, token) => {
  try {
    const response = await axios.delete(SERVER_URL + "/user/" + userId, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const DeboxAccessToken = async (code, userId) => {
  try {
    const response = await axios.get('https://open.debox.pro/openapi/oauth2/access_token', {
      params: {
        grant_type: 'authorization_code',
        code,
        app_id: 'FpinqNBFKsCpGLA6', // Replace with your app ID
        app_secret: 'VHTv9xBIzJIvuwKqBVk6zZhEktToAXP7', // Replace with your app secret
        user_id: userId, // Replace with user ID if needed
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching access token', error);
  }
};


const ShareDeboxMessage = async (title, content) => {
  const url = "https://open.debox.pro/openapi/messages/group/send";
  const apiKey = "Ia4faTQxQXd8Dai5"; // Replace with your actual API key

  const data = {
    group_id: "oo0dsrum",
    object_name: "text",
    title: title,
    content: content,
  };

  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": apiKey,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export {
  UserLogin,
  GetUser,
  GetUserByUsername,
  UpdateUser,
  UpdateUserById,
  GetUserByID,
  GetUsers,
  SearchUser,
  CreateUser,
  DeleteUser,
  GetUserPaymentMethods,
  CreateUserPaymentMethod,
  UpdateUserPaymentMethod,
  GetUserRatings,
  GetTokenBalance,
  VerifyEmail,
  ResendEmailVerification,
  InitiateKYC,
  DeboxAccessToken, 
  ShareDeboxMessage 
};
