import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Button, Stack, Select, useMediaQuery } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Icon } from "@chakra-ui/react";
import { MdArrowForward } from "react-icons/md";
import Logo from "./Logo";
import { connections } from "variables/connections";

const NavBar = (props) => {
  const [isMdOrSm] = useMediaQuery("(max-width: 960px)");
  const { handleLogin, handleLogout, handleConnect, isConnected, address, loginLoading } = props;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const ethereumAddressDisplay = (ethereumAddress) => {
    // Assuming ethereumAddress is a string with a valid Ethereum address
    const displayedAddress =
      ethereumAddress.length >= 6
        ? ethereumAddress.slice(0, 3) + ".." + ethereumAddress.slice(-3)
        : ethereumAddress;

    return displayedAddress;
  };

  return (
    <NavBarContainer {...props}>
      {!isMdOrSm && (
      <Logo
        w="100px"
        color={["white", "white", "primary.500", "primary.500"]}
      />)}
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks
        isOpen={isOpen}
        handleConnect={handleConnect}
        handleLogout={handleLogout}
        isConnected={isConnected}
        address={address}
        ethereumAddressDisplay={ethereumAddressDisplay}
        loginLoading={loginLoading}
      />
    </NavBarContainer>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const MenuItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Link to={to}>
      <Text
        display="block"
        fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
        fontFamily="ManropeRegular"
      >
        {children}
      </Text>
    </Link>
  );
};

const MenuLinks = ({
  isOpen,
  handleConnect,
  handleLogout,
  isConnected,
  address,
  ethereumAddressDisplay,
  loginLoading
}) => {
  const defaultChain = localStorage.getItem("chainId") !== undefined && localStorage.getItem("chainId") !== null ? localStorage.getItem("chainId") : "";
  const [network, setNetwork] = useState(defaultChain);
  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      <Stack
        spacing={8}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        {isConnected && (
          <Button
            onClick={handleLogout}
            className="banner-btn"
            size="md"
            rounded={"full"}
            color="white"
            fontFamily="ManropeLight"
            background="brand.500"
            _hover={{
              bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
            }}
          >
            {ethereumAddressDisplay(address)}
            <Icon
              as={MdArrowForward}
              width="20px"
              height="20px"
              color="white"
            />
          </Button>
        )}

        {!isConnected && (
          <>
            <Select
              placeholder="Select Network"
              defaultValue={network}
              onChange={(e) => {
                setNetwork(e.target.value);
              }}
            >
              {Object.entries(connections).map(([key, network]) => (
                <option key={key} value={key}>
                  {network.chainName}
                </option>
              ))}
            </Select>
            <Button
              onClick={(e) => {
                handleConnect(network);
              }}
              isLoading={loginLoading}
              loadingText="Wait.."
              className="banner-btn"
              fontSize={{ sm: "sm", md: "md" }}
              size="md"
              pl="7"
              pr="7"
              rounded={"full"}
              color="white"
              background="brand.500"
              fontFamily="ManropeLight"
              _hover={{
                bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
              }}
            >
              Connect
              <Icon
                as={MdArrowForward}
                width="20px"
                height="20px"
                color="white"
              />
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  return (
      <Box
        width="100%"
        background="#01001e"
        borderRadius="10px"
        p="2"
        mx="2"
        mt="2"
      >
          <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            pt={2}
            pb={2}
            pl={3}
            pr={3}
            bg={["primary.500", "primary.500", "primary.500", "primary.500"]}
            color={["#fff", "#fff", "#fff", "#fff"]}
            {...props}
          >
            {children}
          </Flex>
      </Box>
  );
};

export default NavBar;
