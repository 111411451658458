import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Flex,
  Text,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  NumberInput,
  NumberInputField,
  FormControl,
  FormLabel,
  Select,
  Input,
  SimpleGrid,
  VStack,
  HStack,
  Skeleton,
  SkeletonText,
  useToast,
  useDisclosure,
  useMediaQuery
} from "@chakra-ui/react";
import { GetAssets, GetCurrencies, GetPaymentMethods } from "services/miscService";
import { GetUserPaymentMethods, CreateUserPaymentMethod, GetTokenBalance } from "services/userService";
import CreateBuyAd from "./components/CreateBuyAd.js";
import CreateSellAd from "./components/CreateSellAd.js";
import BuyAd from "./components/BuyAd.js";
import SellAd from "./components/SellAd.js";
import { CreateBuyAdvert, CreateSellAdvert, ContractCreateSellAdvert, ContractCreateBuyAdvert, ContractCloseSellAdvert, ContractCloseBuyAdvert, ContractFundSellAdvert, UpdateSellAdvert, UpdateBuyAdvert, GetUserSellAds, GetUserBuyAds } from "services/adService";
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { useSelector } from "react-redux";
import { AiFillNotification } from "react-icons/ai";
import NETWORKS from "contracts/Networks.json";

export default function Index() {
  const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isFundAdOpen, onOpen: onFundAdOnOpen, onClose: onFundAdOnClose } = useDisclosure();
  const { isOpen: isCreateUserPaymentMethodOpen, onOpen: onCreateUserPaymentMethodOpen, onClose: onCreateUserPaymentMethodClose } = useDisclosure();
  const parseBool = (str) => {
    return str.toLowerCase() === 'true';
  };

  const userState = useSelector((state) => state?.user?.value);
  const userId = userState.hasOwnProperty("userId") ? userState.userId : null;
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const { walletProvider } = useWeb3ModalProvider();
  const { isConnected, address, chainId } = useWeb3ModalAccount();

  const toast = useToast();
  const [adId, setAdId] = useState("");
  const [adType, setAdType] = useState("");
  const [step, setStep] = useState("");
  const [buyStep, setBuyStep] = useState(1);
  const [sellStep, setSellStep] = useState(1);
  const [buyLoading, setBuyLoading] = useState(false);
  const [sellLoading, setSellLoading] = useState(false);
  const [buyAdsLoading, setBuyAdsLoading] = useState(false);
  const [sellAdsLoading, setSellAdsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isMdOrSm] = useMediaQuery("(max-width: 960px)");

  const defaultSellData = {
    priceType: "floating",
    factor: 100,
    asset: null,
    currency: null,
    currencyRate: 1,
    assetPrice: 1,
    price: 412.2765,
    upperLimit: 0,
    lowerLimit: 0,
    totalAsset: 1,
    paymentMethod: "",
    method: "",
    deadline: 15,
    notes: "",
    network: chainId,
    conditions: { kycVerified: "true" }
  }

  const defaultBuyData = {
    priceType: "floating",
    factor: 100,
    asset: null,
    currency: null,
    currencyRate: 1,
    assetPrice: 1,
    price: 412.2765,
    upperLimit: 0,
    lowerLimit: 0,
    totalAsset: 1,
    paymentMethod: "",
    deadline: 15,
    notes: "",
    network: chainId,
    conditions: { kycVerified: "true" }
  };

  const [sellAd, setSellAd] = useState(defaultSellData);
  const [buyAd, setBuyAd] = useState(defaultBuyData);
  const [currencies, setCurrencies] = useState(null);
  const [assets, setAssets] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [userPaymentMethods, setUserPaymentMethods] = useState(null);
  const [buyAds, setBuyAds] = useState(null);
  const [sellAds, setSellAds] = useState(null);
  const [currentMethod, setCurrentMethod] = useState(null);
  const [currentMethodDetails, setCurrentMethodDetails] = useState([]);
  const [formData, setFormData] = useState({});
  const [title, setTitle] = useState("My Adverts");
  const [loading, setLoading] = useState(false);
  const [createPaymentLoading, setCreatePaymentLoading] = useState(false);

  const handleCreateBuyAd = async () => {
    try {
      let buyAdData = { ...buyAd };
      buyAdData["network"] = chainId.toString();
      if (parseFloat(buyAdData.lowerLimit) === 0) {
        toast({
          title: "Lower limit must be greater than 0",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (parseFloat(buyAdData.upperLimit) === 0) {
        toast({
          title: "Upper limit must be greater than 0",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (parseFloat(buyAdData.totalAsset) <= 0) {
        toast({
          title: "Asset amount must be greater than 0",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (parseFloat(buyAdData.lowerLimit) > parseFloat(buyAdData.upperLimit)) {
        toast({
          title: "Lower limit must not be greater than upper limit",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      buyAdData['asset'] = (assets.find((a) => a.symbol == buyAd.asset))?._id;
      buyAdData['paymentMethod'] = (paymentMethods.find((m) => m._id == buyAd.paymentMethod))?._id;
      buyAdData['currency'] = (currencies.find((c) => c.symbol == buyAd.currency))?._id;
      buyAdData['conditions'] = { kycVerified: parseBool(buyAd.conditions.kycVerified) }
      buyAdData['balance'] = buyAd.totalAsset;
      buyAdData['unit'] = buyAd.totalAsset;
      setBuyLoading(true);
      let response = await CreateBuyAdvert(buyAdData, token);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setBuyLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setBuyLoading(false);
        return;
      }
      if (response.success) {
        buyAdData["advertID"] = response.data._id;
        buyAdData["symbol"] = buyAd.asset.toUpperCase();
        const advertID = response.data._id;
        const walletConnect = { isConnected, walletProvider, chainId };
        response = await ContractCreateBuyAdvert(walletConnect, buyAdData);
        if (!response || !response?.success) {
          toast({
            title: "An error occured on the blockchain",
            status: "error",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          setBuyLoading(false);
          return;
        }
        const adData = { createdOnBlockchain: true, transactionHash: response.transactionHash };
        response = await UpdateBuyAdvert(adData, advertID, token);
        if (!response || !response?.success) {
          toast({
            title: "An error occured on the blockchain",
            status: "error",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          setBuyLoading(false);
          return;
        }
        toast({
          title: "Advert created successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });

        setStep("");
        setBuyAd(defaultBuyData);
        getAssets();
        getCurrencies();
        setBuyStep(1);
        getBuyAds();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setBuyLoading(false);
    }
  };

  const handleCreateSellAd = async () => {
    try {
      let sellAdData = { ...sellAd };
      sellAdData["network"] = chainId.toString();
      if (parseFloat(assetBalance) < parseFloat(sellAdData.totalAsset)) {
        toast({
          title: "Asset balance is not sufficient to create this Ad",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (parseFloat(sellAdData.lowerLimit) === 0) {
        toast({
          title: "Lower limit must be greater than 0",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (parseFloat(sellAdData.upperLimit) === 0) {
        toast({
          title: "Upper limit must be greater than 0",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (parseFloat(sellAdData.totalAsset) <= 0) {
        toast({
          title: "Asset amount must be greater than 0",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (parseFloat(sellAdData.lowerLimit) > parseFloat(sellAdData.upperLimit)) {
        toast({
          title: "Lower limit must not be greater than upper limit",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      sellAdData['asset'] = (assets.find((a) => a.symbol == sellAd.asset))?._id;
      sellAdData['currency'] = (currencies.find((c) => c.symbol == sellAd.currency))?._id;
      sellAdData['conditions'] = { kycVerified: parseBool(sellAd.conditions.kycVerified) }
      sellAdData['balance'] = 0;
      sellAdData['unit'] = sellAd.totalAsset;
      setSellLoading(true);
      let response = await CreateSellAdvert(sellAdData, token);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setSellLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setSellLoading(false);
        return;
      }
      if (response.success) {
        sellAdData["symbol"] = sellAd.asset.toUpperCase();
        sellAdData["advertID"] = response.data._id;
        sellAdData['amount'] = sellAd.totalAsset;
        const advertID = response.data._id;
        const walletConnect = { isConnected, walletProvider, chainId };
        response = await ContractCreateSellAdvert(walletConnect, sellAdData);
        if (response.success) {
          //update the buy trade funded status
          const adData = { createdOnBlockchain: true, transactionHash: response.transactionHash };
          response = await UpdateSellAdvert(adData, advertID, token);
          if (!response || !response?.success) {
            toast({
              title: "An error occured on the blockchain",
              status: "error",
              duration: 9000,
              position: "top-right",
              isClosable: true,
            });
            setSellLoading(false);
            return;
          }
          setSellLoading(false);
          toast({
            title: "Advert created, network confirmation in progress",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          setStep("");
          setSellAd(defaultSellData);
          getAssets();
          getCurrencies();
          setSellStep(1);
          getSellAds();
        }
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setSellLoading(false);
    }
  };

  const handleUpdateSellAd = async (adData, adId) => {
    try {
      setSellAdsLoading(true);
      const response = await UpdateSellAdvert(adData, adId, token);
      setSellAdsLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setStep("");
        getSellAds();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleUpdateBuyAd = async (adData, adId) => {
    try {
      setBuyAdsLoading(true);
      const response = await UpdateBuyAdvert(adData, adId, token);
      setBuyAdsLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setStep("");
        getBuyAds();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const [fundAdData, setFundAdData] = useState({
    amount: 0,
    advertID: "",
    symbol: ""
  });
  const [fundAdLoading, setFundAdLoading] = useState(false);

  const handleFundAd = async () => {
    try {
      setFundAdLoading(true);
      fundAdData["symbol"] = sellAd.asset.toUpperCase();

      const walletConnect = { isConnected, walletProvider, chainId };
      const response = await ContractFundSellAdvert(walletConnect, fundAdData);
      setFundAdLoading(false);
      toast({
        title: "Advert has been funded, waiting for confirmation",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      onFundAdOnClose();
      getSellAds();
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setFundAdLoading(false);
    }
  };

  const handleDeleteAd = async () => {
    try {
      setDeleteLoading(true);
      if (adType === "buy") {
        const walletConnect = { isConnected, walletProvider, chainId };
        let response = await ContractCloseBuyAdvert(walletConnect, adId);
        if (response.success) {
          const adData = { status: "closed", deleted: true, balance: 0, transactionHash: response.transactionHash };
          response = await UpdateBuyAdvert(adData, adId, token);
          if (!response || !response?.success) {
            toast({
              title: "An error occured on the blockchain",
              status: "error",
              duration: 9000,
              position: "top-right",
              isClosable: true,
            });
            setDeleteLoading(false);
            return;
          }
          toast({
            title: "Advert deleted successfully",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          getBuyAds();
          setStep("");
          onClose();
          setDeleteLoading(false);
        }
      }
      else if (adType === "sell") {
        const walletConnect = { isConnected, walletProvider, chainId };
        let response = await ContractCloseSellAdvert(walletConnect, adId);
        if (response.success) {
          const adData = {
            deleted: true,
            status: "closed",
            funded: false,
            initialFunded: false,
            balance: 0,
            transactionHash: response.transactionHash
          };
          response = await UpdateSellAdvert(adData, adId, token);
          if (!response || !response?.success) {
            toast({
              title: "An error occured on the blockchain",
              status: "error",
              duration: 9000,
              position: "top-right",
              isClosable: true,
            });
            setDeleteLoading(false);
            return;
          }
          toast({
            title: "Advert deleted successfully",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          setStep("");
          onClose();
          getSellAds();
          setDeleteLoading(false);
        }
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setDeleteLoading(false);
    }
  };

  const [assetBalance, setAssetBalance] = useState(0);
  const handleGetBalance = async (asset) => {
    const walletConnect = { isConnected, chainId, address, walletProvider };
    if (chainId !== undefined && asset !== undefined) {
      const balance = await GetTokenBalance(walletConnect, NETWORKS[chainId][asset], address);
      setAssetBalance(balance);
    }
  }

  const getSellAds = async () => {
    try {
      setSellAdsLoading(true);
      const filter = {};
      const response = await GetUserSellAds(userId, filter, token);
      setSellAdsLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setSellAds(response.data);
        return;
      }
    } catch (error) {
      setSellAdsLoading(false);
    }
  };

  const getBuyAds = async () => {
    try {
      setBuyAdsLoading(true);
      const filter = {};
      const response = await GetUserBuyAds(userId, filter, token);
      setBuyAdsLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setBuyAds(response.data);
        return;
      }
    } catch (error) {
      setBuyAdsLoading(false);
    }
  };

  const getCurrencies = async () => {
    try {
      setLoading(true);
      const response = await GetCurrencies();
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        const currency = response.data[0];
        setBuyAd(prevBuyAd => ({
          ...prevBuyAd,
          currency: currency.symbol,
          currencyRate: currency.rateToDollar["$numberDecimal"]
        }));
        setSellAd(prevSellAd => ({
          ...prevSellAd,
          currency: currency.symbol,
          currencyRate: currency.rateToDollar["$numberDecimal"]
        }));
        setCurrencies(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getAssets = async () => {
    try {
      setLoading(true);
      const response = await GetAssets();
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        const asset = response.data[0];
        setBuyAd(prevBuyAd => ({
          ...prevBuyAd,
          asset: asset.symbol,
          assetPrice: asset.rateToDollar["$numberDecimal"],
        }));
        setSellAd(prevSellAd => ({
          ...prevSellAd,
          asset: asset.symbol,
          assetPrice: asset.rateToDollar["$numberDecimal"],
        }));
        setAssets(response.data);
        handleGetBalance(asset.symbol);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getPaymentMethods = async () => {
    try {
      setLoading(true);
      const response = await GetPaymentMethods();
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setPaymentMethods(response.data);
        let currency;
        if (buyAd.currency !== null) {
          currency = currencies.find((c) => c.symbol === buyAd.currency);
          setBuyAd(prevBuyAd => ({
            ...prevBuyAd,
            price: Number(currency.rateToDollar["$numberDecimal"] * prevBuyAd.assetPrice * Number(prevBuyAd.factor / 100)).toFixed(2),
          }));
        }
        if (sellAd.currency !== null) {
          currency = currencies.find((c) => c.symbol === sellAd.currency);
          setSellAd(prevSellAd => ({
            ...prevSellAd,
            price: Number(currency.rateToDollar["$numberDecimal"] * prevSellAd.assetPrice * Number(prevSellAd.factor / 100)).toFixed(2),
          }));
        }
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [paymentData, setPaymentData] = useState({});
  const handleChange = (e, label) => {
    setPaymentData({
      ...paymentData,
      [label]: e.target.value,
    });
  };

  const handleCreatePaymentMethod = async () => {
    try {
      setCreatePaymentLoading(true);
      const userPaymentData = { ...paymentData, paymentMethod: currentMethod?._id }
      const response = await CreateUserPaymentMethod(userPaymentData, token);
      setCreatePaymentLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onCreateUserPaymentMethodClose();
        getUserPaymentMethods();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setCreatePaymentLoading(false);
    }
  };

  const handleSelectChange = (e) => {
    const selectedMethod = paymentMethods.find(
      (method) => method._id === e.target.value
    );
    setCurrentMethod(selectedMethod);
    setCurrentMethodDetails(JSON.parse(selectedMethod.details));
  };


  const getUserPaymentMethods = async () => {
    try {
      setLoading(true);
      const response = await GetUserPaymentMethods(token);
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setUserPaymentMethods(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const testContract = async () => {
    let sellAdData = [];
    sellAdData["symbol"] = "USDT";
    const walletConnect = { isConnected, chainId, address, walletProvider };
    //alert(JSON.stringify(walletConnect));
    await ContractCreateSellAdvert(walletConnect, sellAdData);
  }

  /*socket.onclose = () => {
    console.log('WebSocket connection closed');
  };*/

  useEffect(() => {
    getAssets();
    getCurrencies();
  }, []);


  useEffect(() => {
    if (isConnected && token) {
      const handleOpen = () => {
        socket.send(JSON.stringify({
          type: 'REGISTER',
          payload: {
            clientId: address.toLowerCase(),
          },
        }));
      };
  
      const handleMessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'FUNDING_SUCCESS') {
          getSellAds();
          toast({
            title: "Ad funding completed",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
        } else if (message.type === 'AD_CLOSE_SUCCESS') {
          getSellAds();
          getBuyAds();
          setDeleteLoading(false);
          toast({
            title: "Ad closed successfully",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
        }
      };
  
      socket.onopen = handleOpen;
      socket.onmessage = handleMessage;
  
      // Cleanup function
      return () => {
        socket.onopen = null;
        socket.onmessage = null;
      };
    }
  }, [isConnected, token, address, getSellAds, getBuyAds, setDeleteLoading, toast]);
  

  useEffect(() => {
    if (currencies !== null && assets !== null) {
      getPaymentMethods();
    }
  }, [currencies, assets]);

  useEffect(() => {
    if (token !== null) {
      getUserPaymentMethods();
      getSellAds();
      getBuyAds();
    } else {
      setBuyAds(null);
      setSellAds(null);
      setUserPaymentMethods(null);
    }
  }, [token]);


  useEffect(() => {
    if (chainId === undefined) return;
    handleGetBalance(sellAd.asset);
  }, [chainId, sellAd.asset, buyAd.asset]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={'xs'}>
        <ModalOverlay />
        <ModalContent background="brand.500">
          <ModalHeader color="#ffffff">Delete Confirmation</ModalHeader>
          <ModalCloseButton color="#ffffff" />
          <ModalBody>
            <Heading size="sm" textAlign={"center"} color="#ffffff"> Are you sure you want to delete Ad?</Heading>
            <Text mt="5" textAlign={"center"} color="#ffffff"> Click on <b>Confirm</b> to to proceed </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              background="secondary.500"
              color="white"
              _hover={{ color: "#000000", background: "#ffffff" }}
              isLoading={deleteLoading}
              loadingText="Wait.."
              onClick={handleDeleteAd}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isFundAdOpen} onClose={onFundAdOnClose} size={'xs'}>
        <ModalOverlay />
        <ModalContent background="brand.500">
          <ModalHeader color="white">Fund Ad</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <FormControl isRequired={true}>
              <FormLabel color={'white'} fontWeight="bold">
                Asset Amount
              </FormLabel>
              <NumberInput defaultValue={fundAdData.amount}>
                <NumberInputField
                  color="white"
                  required={true}
                  onChange={(e) => {
                    setFundAdData(prevFundAdData => ({
                      ...prevFundAdData,
                      amount: e.target.value
                    }));
                  }}
                />
              </NumberInput>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onFundAdOnClose}>
              Cancel
            </Button>
            <Button background="secondary.500"
              color="white"
              _hover={{ color: "#000000", background: "#ffffff" }} isLoading={fundAdLoading} loadingText="Wait.." onClick={handleFundAd}>Fund</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isCreateUserPaymentMethodOpen} onClose={onCreateUserPaymentMethodClose} size={'lg'}>
        <ModalOverlay />
        <ModalContent background="brand.500">
          <ModalHeader color="white">Create Payment Method</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <FormControl>
              <FormLabel color={'white'} fontWeight="bold">
                Payment Option
              </FormLabel>
              <Select
                color="white"
                placeholder="Select Payment Option"
                onChange={handleSelectChange}
              >
                {paymentMethods !== null && paymentMethods.map((method) => <option key={method._id} value={method._id}>{method.name}</option>)}
              </Select>
            </FormControl>

            {currentMethodDetails.map((detail) => (
              <FormControl key={detail.label} mb={2} mt="4">
                <FormLabel color="white" fontWeight="bold">
                  {detail.label}
                </FormLabel>
                <Input
                  color="white"
                  maxLength={100}
                  type={detail.type === 'number' ? 'number' : 'text'}
                  placeholder={detail.instruction}
                  onChange={(e) => handleChange(e, detail.label)}
                />
              </FormControl>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onCreateUserPaymentMethodClose}>
              Cancel
            </Button>
            <Button
              background="secondary.500"
              color="white"
              _hover={{ color: "#000000", background: "#ffffff" }}
              isLoading={createPaymentLoading} loadingText="Wait.." onClick={handleCreatePaymentMethod}>Add</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} p="3" width="100%">
        <Box width="100%" mt="5" mb="5">
          <Heading color="white" fontSize={{ sm: "md", lg: "xl" }}>
            {title}
          </Heading>
        </Box>

        <Box width="100%" textAlign="right">
          {
            (step === "sellad" || step === "buyad") &&
            <Button
              isLoading={loading}
              loadingText="Please Wait"
              onClick={() => {
                //testContract();
                setStep("select");
                setTitle("Create Ad");
              }}
            >
              Back
            </Button>
          }

          {
            (step === "") &&
            <Button
              variant="solid"
              background="secondary.500"
              isLoading={loading}
              loadingText="Please Wait"
              color="white"
              borderRadius="3"
              _hover={{ color: "white" }}
              onClick={() => {
                setStep("select");
                setTitle("Create Ad");
              }}
            >
              <AiFillNotification />&nbsp;Create Ad
            </Button>
          }
        </Box>

        {
          step === "select" && <>
            <SimpleGrid
              width="100%"
              mt="10"
              mb="20"
              columns={{ sm: "1", md: "2" }}
              spacing="2"
            >
              <Box borderWidth="1px" p="5" borderColor="gray.500" minH="150" onClick={() => { setStep("buyad"); setTitle("Create Buy Advert"); }}>
                <Center width="100%" height="120" mt="5">
                <Flex>
                <AiFillNotification color="white" size="40" />
                  <Box ml="2">
                    <Heading color="white" fontSize={{ sm: "lg", lg: "2xl" }}>Create Buy Ad</Heading>
                    <Text mt="5" color="white" fontSize={{ sm: "md", lg: "lg" }}>Buy advert allows you to buy crypto asset from trade counterpart with fiat</Text>
                  </Box>
                 </Flex>
                </Center>
              </Box>
              <Box borderWidth="1px" p="5" borderColor="gray.500" minH="100" onClick={() => { setStep("sellad"); setTitle("Create Sell Advert"); }}>
                <Center width="100%" height="120" mt="5">
                  <Flex>
                    <AiFillNotification color="white" size="40" />
                  <Box ml="2">
                    <Heading color="white" fontSize={{ sm: "lg", lg: "2xl" }}>Create Sell Ad</Heading>
                    <Text mt="5" color="white" fontSize={{ sm: "md", lg: "lg" }}>Sell advert allows you to sell crypto asset to trade counterpart for fiat</Text>
                  </Box>
                  </Flex>
                </Center>
              </Box>
            </SimpleGrid>

            <Box textAlign="center"> <Text color="white" onClick={() => { setStep(""); setTitle("Ads"); }}>View Ads</Text> </Box>
          </>
        }
        {
          step === "sellad" && sellAd.asset !== null && sellAd.currency !== null && <CreateSellAd setSellStep={setSellStep} sellStep={sellStep} sellLoading={sellLoading} sellAd={sellAd} setSellAd={setSellAd} currencies={currencies} assets={assets} userPaymentMethods={userPaymentMethods} handleCreateSellAd={handleCreateSellAd} actionType={"Create"} assetBalance={assetBalance} onCreateUserPaymentMethodOpen={onCreateUserPaymentMethodOpen} chainId={chainId} />
        }
        {
          step === "buyad" && buyAd.asset !== null && buyAd.currency !== null && <CreateBuyAd setBuyStep={setBuyStep} buyStep={buyStep} buyLoading={buyLoading} buyAd={buyAd} setBuyAd={setBuyAd} currencies={currencies} assets={assets} paymentMethods={paymentMethods} handleCreateBuyAd={handleCreateBuyAd} actionType={"Create"} chainId={chainId} />
        }

        {
          step === "" &&
          <>
            <Box width="100%" mt="5" mb="3">
              <Heading color="secondary.500" fontSize={{ sm: "md", md: "xl" }}>
                Buy Ads
              </Heading>
            </Box>
            {buyAdsLoading ? (
              <LoadingAds />
            ) : (
              <VStack
                width="100%"
                mt="3"
                mb="3"
                spacing="3"
              >
                {!isMdOrSm && (
                  <HStack width="100%" p="5">
                    <Box width="90%">
                      <SimpleGrid columns={{ base: 1, sm: 2, md: 5 }} width="100%" spacing="2">
                        <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Merchant</Text></Box>
                        <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Price</Text></Box>
                        <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Available Order | Limit</Text></Box>
                        <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Payment Method</Text></Box>
                        <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Balance</Text></Box>
                      </SimpleGrid>
                    </Box>
                    <Box width="10%">
                      <Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Action</Text>
                    </Box>
                  </HStack>)}
                {
                  buyAds !== null && buyAds.length > 0 ? buyAds.map((ad) => <BuyAd key={ad._id} ad={ad} handleUpdateBuyAd={handleUpdateBuyAd} onOpen={onOpen} setAdId={setAdId} setAdType={setAdType} />) : <Flex> <AiFillNotification color="white" size="25" /> &nbsp; <Text align="center" color="white">  No buy ads yet</Text> </Flex>
                }
              </VStack>
            )}


            <Box width="100%" mt="10" mb="3">
              <Heading color="secondary.500" fontSize={{ sm: "md", md: "xl" }}>
                Sell Ads
              </Heading>
            </Box>
            {sellAdsLoading ? (
              <LoadingAds />
            ) : (
              <VStack
                width="100%"
                mt="3"
                mb="3"
                spacing="3"
              >
                {!isMdOrSm && (
                  <HStack width="100%" p="5">
                    <Box width="90%">
                      <SimpleGrid columns={{ base: 1, sm: 2, md: 5 }} width="100%" spacing="2">
                        <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Merchant</Text></Box>
                        <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Price</Text></Box>
                        <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Available Order | Limit</Text></Box>
                        <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Payment Method</Text></Box>
                        <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Balance</Text></Box>
                      </SimpleGrid>
                    </Box>
                    <Box width="10%">
                      <Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Action</Text>
                    </Box>
                  </HStack>
                )}
                {
                  sellAds !== null && sellAds.length > 0 ? sellAds.map((ad) => <SellAd key={ad._id} ad={ad} handleUpdateSellAd={handleUpdateSellAd} onOpen={onOpen} setAdId={setAdId} setAdType={setAdType} setFundAdData={setFundAdData} onFundAdOnOpen={onFundAdOnOpen} />) : <Flex> <AiFillNotification color="white" size="25" /> &nbsp; <Text align="center" color="white">  No sell ads yet</Text> </Flex>
                }
              </VStack>
            )}
          </>
        }

        {/*matches.length > 0 && (
          <Box width="100%" mt="5">
            <Paginate
              pageCount={pageCount}
              setFrom={setFrom}
              getRecords={getMatches}
            />
          </Box>
        )*/}
      </Box>
    </>
  );
}

const LoadingAds = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="10"
        mb="20"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};
