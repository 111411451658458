// Chakra imports
import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import Footer from "components/navigation/Footer.js";
import { WebContext } from "contexts/WebContext";

export default function WebLayout() {
  //const { ...rest } = props;

  const color = "";
  const others = "";
  return (
    <WebContext.Provider
      value={{
        color,
        others,
      }}
    >
      <Box minH="100vh" background="white">
        <Outlet />
        <Footer />
      </Box>
    </WebContext.Provider>
  );
}
