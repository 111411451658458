import { ethers, BrowserProvider } from "ethers";
import axiosInstance from './axiosInstance'; // Adjust the path accordingly
import BUYTRADEABI from "../contracts/Trade.json";
import SELLTRADEABI from "../contracts/BuyAdvert.json";
import TOKENABI from "../contracts/IBEP20.json";
import NETWORKS from "../contracts/Networks.json";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const CreateBuyTrade = async (buyTradeData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/trade/buy/create", buyTradeData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const MarkFundedBuyTrade = async (buyTradeData, tradeId, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + "/trade/buy/funded/"+tradeId, buyTradeData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CreateSellTrade = async (sellTradeData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/trade/sell/create", sellTradeData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CreateBuyTradeContract = async (walletConnect, buyTradeContractData) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const { chainId } = walletConnect;
    const BUY_TRADE_CONTRACT = NETWORKS[chainId]["BUY_TRADE_CONTRACT"];
    
    const signer = await ethersProvider.getSigner();
    const BuyTradeContract = new ethers.Contract(
      BUY_TRADE_CONTRACT,
      BUYTRADEABI.abi,
      signer
    );
    const wei = ethers.parseEther(String(buyTradeContractData.unit));
    const transaction = await BuyTradeContract.createTrade(buyTradeContractData.tradeId, buyTradeContractData.advertId, wei);
    const TIMEOUT_MS = 90000; // 1 minute
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
    );
    const receipt = await Promise.race([transaction.wait(), timeoutPromise]);
    if (receipt && receipt.status === 1) {
      // Transaction was successful
      const transactionHash = transaction.hash;
      return {
        success: true,
        transactionHash
      };
    } else {
      throw new Error("Transaction failed");
    }
  } catch (error) {
    let errorMessage = "An unexpected error occurred";
     if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed with an unknown error, contact support`);
  }
};

const CreateSellTradeContract = async (walletConnect, sellTradeContractData) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const { chainId } = walletConnect;
    const ASSET_CONTRACT = NETWORKS[chainId][sellTradeContractData.asset];
    const SELL_TRADE_CONTRACT = NETWORKS[chainId]["SELL_TRADE_CONTRACT"];

    const signer = await ethersProvider.getSigner();

    const Token = new ethers.Contract(ASSET_CONTRACT, TOKENABI.abi, signer);
    const SellTradeContract = new ethers.Contract(
      SELL_TRADE_CONTRACT,
      SELLTRADEABI.abi,
      signer
    );
    const wei = ethers.parseEther(String(sellTradeContractData.unit));
    let transaction = await Token.approve(SELL_TRADE_CONTRACT, wei);
    const TIMEOUT_MS = 90000; // 1 minute
    let timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
    );
    let receipt = await Promise.race([transaction.wait(), timeoutPromise]);
    if (!receipt && receipt.status !== 1) {
      throw new Error("Transaction failed");
    } 
  
    transaction = await SellTradeContract.createSellTrade(sellTradeContractData.tradeId, sellTradeContractData.advertId, wei);
    timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
    );
    receipt = await Promise.race([transaction.wait(), timeoutPromise]);
    if (receipt && receipt.status === 1) {
      // Transaction was successful
      const transactionHash = transaction.hash;
      return {
        success: true,
        transactionHash
      };
    } else {
      throw new Error("Transaction failed");
    }
  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
     switch (error.code) {
       case 4001:
         errorMessage = "Transaction rejected by user";
         break;
       case "ACTION_REJECTED":
         errorMessage = "Transaction rejected by user";
         break;
       default:
         errorMessage = error.message || "An unexpected error occurred";
     }
   } else if (error.hasOwnProperty("error")) {
     if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
       errorMessage = error.error.data.message;
     } else if (error.error.hasOwnProperty("message")) {
       errorMessage = error.error.message;
     }
   } else if (error.hasOwnProperty("message")) {
     errorMessage = error.message;
   }
   throw new Error(`Transaction failed with an unknown error, contact support`);
 }
};

const CancelSellTradeContract = async (walletConnect, tradeId) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const { chainId } = walletConnect;
    const SELL_TRADE_CONTRACT = NETWORKS[chainId]["SELL_TRADE_CONTRACT"];
    
    const signer = await ethersProvider.getSigner();
    const SellTradeContract = new ethers.Contract(
      SELL_TRADE_CONTRACT,
      SELLTRADEABI.abi,
      signer
    );
    const transaction = await SellTradeContract.closeTrade(tradeId);
    const TIMEOUT_MS = 90000; // 1 minute
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
    );
    const receipt = await Promise.race([transaction.wait(), timeoutPromise]);
    if (receipt && receipt.status === 1) {
      // Transaction was successful
      const transactionHash = transaction.hash;
      return {
        success: true,
        transactionHash
      };
    } else {
      throw new Error("Transaction failed");
    }

  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
     switch (error.code) {
       case 4001:
         errorMessage = "Transaction rejected by user";
         break;
       case "ACTION_REJECTED":
         errorMessage = "Transaction rejected by user";
         break;
       default:
         errorMessage = error.message || "An unexpected error occurred";
     }
   } else if (error.hasOwnProperty("error")) {
     if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
       errorMessage = error.error.data.message;
     } else if (error.error.hasOwnProperty("message")) {
       errorMessage = error.error.message;
     }
   } else if (error.hasOwnProperty("message")) {
     errorMessage = error.message;
   }
   throw new Error(`Transaction failed with an unknown error, contact support`);
 }
};

const CancelBuyTradeContract = async (walletConnect, tradeId) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const { chainId } = walletConnect;
    const BUY_TRADE_CONTRACT = NETWORKS[chainId]["BUY_TRADE_CONTRACT"];
    
    const signer = await ethersProvider.getSigner();
    const BuyTradeContract = new ethers.Contract(
      BUY_TRADE_CONTRACT,
      BUYTRADEABI.abi,
      signer
    );
    const transaction = await BuyTradeContract.closeTrade(tradeId);
    const TIMEOUT_MS = 90000; // 1 minute
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
    );
    const receipt = await Promise.race([transaction.wait(), timeoutPromise]);
    if (receipt && receipt.status === 1) {
      // Transaction was successful
      const transactionHash = transaction.hash;
      return {
        success: true,
        transactionHash
      };
    } else {
      throw new Error("Transaction failed");
    }

  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
     switch (error.code) {
       case 4001:
         errorMessage = "Transaction rejected by user";
         break;
       case "ACTION_REJECTED":
         errorMessage = "Transaction rejected by user";
         break;
       default:
         errorMessage = error.message || "An unexpected error occurred";
     }
   } else if (error.hasOwnProperty("error")) {
     if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
       errorMessage = error.error.data.message;
     } else if (error.error.hasOwnProperty("message")) {
       errorMessage = error.error.message;
     }
   } else if (error.hasOwnProperty("message")) {
     errorMessage = error.message;
   }
   throw new Error(`Transaction failed with an unknown error, contact support`);
 }
};

const TransferAssetSellTradeContract = async (walletConnect, tradeId) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const { chainId } = walletConnect;
    const SELL_TRADE_CONTRACT = NETWORKS[chainId]["SELL_TRADE_CONTRACT"];
    
    const signer = await ethersProvider.getSigner();
    const SellTradeContract = new ethers.Contract(
      SELL_TRADE_CONTRACT,
      SELLTRADEABI.abi,
      signer
    );

    const transaction = await SellTradeContract.transferAsset(tradeId);
    const TIMEOUT_MS = 90000; // 1 minute
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
    );
    const receipt = await Promise.race([transaction.wait(), timeoutPromise]);
    if (receipt && receipt.status === 1) {
      // Transaction was successful
      const transactionHash = transaction.hash;
      return {
        success: true,
        transactionHash
      };
    } else {
      throw new Error("Transaction failed");
    }
  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
     switch (error.code) {
       case 4001:
         errorMessage = "Transaction rejected by user";
         break;
       case "ACTION_REJECTED":
         errorMessage = "Transaction rejected by user";
         break;
       default:
         errorMessage = error.message || "An unexpected error occurred";
     }
   } else if (error.hasOwnProperty("error")) {
     if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
       errorMessage = error.error.data.message;
     } else if (error.error.hasOwnProperty("message")) {
       errorMessage = error.error.message;
     }
   } else if (error.hasOwnProperty("message")) {
     errorMessage = error.message;
   }
   throw new Error(`Transaction failed with an unknown error, contact support`);
 }
};


const TransferAssetBuyTradeContract = async (walletConnect, tradeId) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const { chainId } = walletConnect;
    const BUY_TRADE_CONTRACT = NETWORKS[chainId]["BUY_TRADE_CONTRACT"];
    
    const signer = await ethersProvider.getSigner();
    const BuyTradeContract = new ethers.Contract(
      BUY_TRADE_CONTRACT,
      BUYTRADEABI.abi,
      signer
    );
    
    const transaction = await BuyTradeContract.transferAsset(tradeId);
    const TIMEOUT_MS = 90000; // 1 minute
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
    );
    const receipt = await Promise.race([transaction.wait(), timeoutPromise]);
    if (receipt && receipt.status === 1) {
      // Transaction was successful
      const transactionHash = transaction.hash;
      return {
        success: true,
        transactionHash
      };
    } else {
      throw new Error("Transaction failed");
    }
  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
     switch (error.code) {
       case 4001:
         errorMessage = "Transaction rejected by user";
         break;
       case "ACTION_REJECTED":
         errorMessage = "Transaction rejected by user";
         break;
       default:
         errorMessage = error.message || "An unexpected error occurred";
     }
   } else if (error.hasOwnProperty("error")) {
     if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
       errorMessage = error.error.data.message;
     } else if (error.error.hasOwnProperty("message")) {
       errorMessage = error.error.message;
     }
   } else if (error.hasOwnProperty("message")) {
     errorMessage = error.message;
   }
   throw new Error(`Transaction failed with an unknown error, contact support`);
 }
};

const TransferFiatBuyTrade = async (tradeId, buyTradeData, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + "/trade/buy/paid/"+tradeId, buyTradeData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const DisputeTrade = async (disputeData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/dispute/create", disputeData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const TransferFiatSellTrade = async (tradeId, sellTradeData, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + "/trade/sell/paid/"+tradeId, sellTradeData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetActiveTrades = async (token) => {
  try {
    const buyTrades = await axiosInstance.get(
      SERVER_URL + "/trade/user/activebuy",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    const sellTrades = await axiosInstance.get(
      SERVER_URL + "/trade/user/activesell",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    const trades = buyTrades.data.data.concat(sellTrades.data.data);
    return {
      success: true,
      data: trades
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserSellTrades = async (pagination, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/trade/user/sells?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserBuyTrades = async (pagination, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/trade/user/buys?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetSellTrades = async (filter, pagination, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/trade/sells/all?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const GetBuyTrades = async (filter, pagination, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/trade/buys/all?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetBuyTrade = async (tradeId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/trade/buy/"+tradeId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const GetSellTrade = async (tradeId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/trade/sell/"+tradeId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const ReviewTrade = async (reviewData, tradeId, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/rating/"+tradeId, reviewData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetReview = async (tradeId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/rating/trade/"+tradeId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  CreateBuyTrade,
  CreateBuyTradeContract,
  CreateSellTradeContract,
  CancelSellTradeContract,
  CancelBuyTradeContract,
  TransferAssetSellTradeContract, 
  TransferAssetBuyTradeContract,
  TransferFiatBuyTrade,
  TransferFiatSellTrade,
  CreateSellTrade,
  DisputeTrade,
  GetUserBuyTrades,
  GetUserSellTrades,
  GetActiveTrades,
  GetBuyTrade,
  GetSellTrade,
  GetBuyTrades,
  GetSellTrades,
  ReviewTrade,
  GetReview,
  MarkFundedBuyTrade
};

