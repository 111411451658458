import {  
  Button,
  Input,
  FormControl,
  FormLabel,
  FormHelperText 
} from "@chakra-ui/react";
import Card from "components/card/Card";

export default function DateFilter(props) {
  const { date, setDate, loading, initFilterSubmit } = props;
  return (
    <Card
    w='100%'
    h='200px'
    p='20px'
    mb='20px'
    >
       <form>
        <FormControl>
          <FormLabel htmlFor='date' fontWeight="bold">Filter By Date</FormLabel>
          <Input id='date' type='date' defaultValue={date} onChange={(e)=>setDate(e.target.value)} />
          <FormHelperText>Enter Date.</FormHelperText>
        </FormControl>
        <Button
            me='100%'
            mb='50px'
            w='140px'
            minW='140px'
            mt={{ base: "20px", "2xl": "auto" }}
            variant='brand'
            fontWeight='500' 
            isLoading={loading}
            loadingText="Wait.."
            onClick={(e)=>{ e.preventDefault(); initFilterSubmit(); }} >
            Find
          </Button>
      </form>
    </Card>
  );
}