// Connections
export const connections = {
  421614: {
    chainId: '0x66eee', // 84531 in decimal
    chainName: 'Arbitrum Sepholia Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://endpoints.omniatech.io/v1/arbitrum/sepolia/public'],
    blockExplorerUrls: ['https://sepolia.arbiscan.io/']
  },
  84532: {
    chainId: '0x14a34', // 84531 in decimal
    chainName: 'Base Sepholia Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://base-sepolia.blockpi.network/v1/rpc/public'],
    blockExplorerUrls: ['https://sepolia.basescan.org/']
  },
  97: {
    chainId: '0x61', // 97 in decimal
    chainName: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com/']
  },
  80002: {
    chainId: '0x13882', // 80001 in decimal
    chainName: 'Polygon Amoi',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-amoy.polygon.technology/'],
    blockExplorerUrls: ['https://amoy.polygonscan.com']
  },
  820522: {
    chainId: '0xC852A',
    chainName: 'TSC Testnet',
    nativeCurrency: {
      name: 'tTAS',
      symbol: 'tTAS',
      decimals: 18,
    },
    rpcUrls: ['https://testnet.tscscan.io/testrpc'],
    blockExplorerUrls: ['https://testnet.tscscan.io']
  },
  /*56: {
    chainId: '0x38', // 56 in decimal
    chainName: 'Binance Smart Chain Mainnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com/']
  },
  137: {
    chainId: '0x89', // 137 in decimal
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
  8453: {
    chainId: '0x2105', // 8453 in decimal
    chainName: 'Base Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.base.org'],
    blockExplorerUrls: ['https://basescan.org']
  }*/
};
