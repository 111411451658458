import React from "react";
import { Icon } from "@chakra-ui/react";
import { FiUsers, FiUser, FiHome } from "react-icons/fi";
import {
  FaHandshake, FaCog
} from "react-icons/fa";
import { IoIosFootball } from "react-icons/io";

import Dashboard from "views/admin/default";
import TradeLayout from "views/admin/trades/layout";
import ActiveBuyTrades from "views/admin/trades/activebuytrades";
import ActiveSellTrades from "views/admin/trades/activeselltrades";
import BuyTrades from "views/admin/trades/buytrades";
import SellTrades from "views/admin/trades/selltrades";
import DisputedBuyTrades from "views/admin/trades/disputedbuytrades";
import DisputedSellTrades from "views/admin/trades/disputedselltrades";

import SettingsLayout from "views/admin/settings/layout";
import Roles from "views/admin/settings/roles";
import AddRole from "views/admin/settings/addrole";

import Users from "views/admin/users";
import Admins from "views/admin/users/admins";
import UserLayout from "views/admin/users/layout";
import AddUser from "views/admin/users/adduser";

const routes = [
  {
    name: "Dashboard",
    nameAbbrv: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <Icon as={FiHome} width="20px" height="20px" color="#ffffff" />,
    component: <Dashboard />,
    collapse: false,
    allow: ["Admin", "Member", "all"],
  },
  {
    name: "Trade",
    nameAbbrv: "Trade",
    layout: "/admin",
    path: "trade",
    icon: <Icon as={FaHandshake} width="20px" height="20px" color="#ffffff" />,
    component: <TradeLayout />,
    collapse: true,
    show: true,
    allow: ["Admin", "Member", "all"],
    items: [
      {
        name: "Active Buy Trades",
        layout: "/admin",
        path: "activebuys",
        icon: (
          <Icon as={FaHandshake} width="20px" height="20px" color="white" />
        ),
        component: <ActiveBuyTrades />,
        collapse: false,
        show: true,
        allow: ["Admin", "all"],
      },
      {
        name: "Active Sell Trades",
        layout: "/admin",
        path: "activesells",
        icon: (
          <Icon as={FaHandshake} width="20px" height="20px" color="white" />
        ),
        component: <ActiveSellTrades />,
        collapse: false,
        show: true,
        allow: ["Admin", "all"],
      },
      {
        name: "Past Buy Trades",
        layout: "/admin",
        path: "buys",
        icon: (
          <Icon as={FaHandshake} width="20px" height="20px" color="white" />
        ),
        component: <BuyTrades />,
        collapse: false,
        show: true,
        allow: ["Admin", "all"],
      },
      {
        name: "Past Sell Trades",
        layout: "/admin",
        path: "sells",
        icon: (
          <Icon as={FaHandshake} width="20px" height="20px" color="white" />
        ),
        component: <SellTrades />,
        collapse: false,
        show: true,
        allow: ["Admin", "all"],
      },
      {
        name: "Disputed Buys",
        layout: "/admin",
        path: "disputedbuys",
        icon: (
          <Icon as={FaHandshake} width="20px" height="20px" color="white" />
        ),
        component: <DisputedBuyTrades />,
        collapse: false,
        show: true,
        allow: ["Admin", "all"],
      },
      {
        name: "Disputed Sells",
        layout: "/admin",
        path: "disputedsells",
        icon: (
          <Icon as={FaHandshake} width="20px" height="20px" color="white" />
        ),
        component: <DisputedSellTrades />,
        collapse: false,
        show: true,
        allow: ["Admin", "all"],
      },
    ],
  },
  {
    name: "Settings",
    nameAbbrv: "Settings",
    layout: "/admin",
    path: "settings",
    icon: <Icon as={FaCog} width="20px" height="20px" color="white" />,
    component: <SettingsLayout />,
    collapse: true,
    show: true,
    allow: ["Admin", "all"],
    items: [
      {
        name: "User Roles",
        layout: "/admin",
        path: "index",
        icon: (
          <Icon as={FaCog} width="20px" height="20px" color="white" />
        ),
        component: <Roles />,
        collapse: false,
        show: true,
        allow: ["Admin", "all"],
      },
      {
        name: "Roles",
        layout: "/admin",
        path: "roles",
        icon: (
          <Icon as={FaCog} width="20px" height="20px" color="white" />
        ),
        component: <Roles />,
        collapse: false,
        show: false,
        allow: ["Admin", "all"],
      },
      {
        name: "Add Role",
        layout: "/admin",
        path: "addrole",
        icon: (
          <Icon as={FaCog} width="20px" height="20px" color="white" />
        ),
        component: <AddRole />,
        collapse: false,
        show: false,
        allow: ["Admin", "all"],
      },
      {
        name: "Add Role",
        layout: "/admin",
        path: "addrole/:roleId",
        icon: (
          <Icon as={FaCog} width="20px" height="20px" color="white" />
        ),
        component: <AddRole />,
        collapse: false,
        show: false,
        allow: ["Admin", "all"],
      },
    ],
  },
  {
    name: "Manage Users",
    nameAbbrv: "Members",
    layout: "/admin",
    path: "users",
    icon: <Icon as={FiUsers} width="20px" height="20px" color="white" />,
    component: <UserLayout />,
    collapse: true,
    show: true,
    allow: ["Admin", "all"],
    items: [
      {
        name: "Users",
        layout: "/admin",
        path: "index",
        icon: (
          <Icon as={FiUser} width="20px" height="20px" color="white" />
        ),
        component: <Users />,
        collapse: false,
        show: true,
        allow: ["Admin", "all"],
      },
      {
        name: "Admin Users",
        layout: "/admin",
        path: "admins",
        icon: (
          <Icon as={FiUser} width="20px" height="20px" color="white" />
        ),
        component: <Admins />,
        collapse: false,
        show: true,
        allow: ["Admin", "all"],
      },
      {
        name: "Update User",
        layout: "/admin",
        path: "adduser/:userId",
        icon: (
          <Icon as={FiUser} width="20px" height="20px" color="white" />
        ),
        component: <AddUser />,
        collapse: false,
        show: false,
        allow: ["Admin", "all"],
      },
    ],
  },
];

export default routes;
