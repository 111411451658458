import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import "assets/css/App.css";
import WebLayout from "layouts/web";
import AdminLayout from "layouts/admin";
import UserLayout from "layouts/user";
import WebAuthLayout from "layouts/webauth";
import routes from "routes.js";
import routesuser from "routesuser.js";
import Home from "views/web/home";
import Login from "views/auth";
import Terms from "views/web/terms";
import Aml from "views/web/aml";
import Privacy from "views/web/privacy";
import UpdateAd from "views/app/ads/update";
import Ads from "views/app/ads";
import BuyOffers from "views/app/ads/buyoffers";
import SellOffers from "views/app/ads/selloffers";
import SellOffer from "views/app/ads/selloffer";
import BuyOffer from "views/app/ads/buyoffer";
import Trade from "views/app/trades/trade";
import Profile from "views/app/profile";
import Payment from "views/app/profile/payment";
import User from "views/app/user";
import Staking from "views/app/staking";
import Faucet from "views/app/faucet";
import { setUser } from "utils/userSlice";
import { UserLogin } from "services/userService";
import { useSelector, useDispatch, Provider } from "react-redux";
import { createWeb3Modal, defaultConfig, useDisconnect, useSwitchNetwork, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "utils/store";
import { connections } from "variables/connections";
import useLastActive from 'hooks/useLastActive';

const projectId = "b160222e5051936f2cc183924976ac5d";
// 2. Set chains
const bsctestnet = {
  chainId: 97,
  name: "BSC Testnet",
  currency: "BNB",
  explorerUrl: "https://explorer.binance.org/smart-testnet",
  rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
};
const bscmainnet = {
  chainId: 56,
  name: "BSC Mainnet",
  currency: "BNB",
  explorerUrl: "https://explorer.binance.org/smart-testnet",
  rpcUrl: "https://bsc-dataseed1.binance.org",
};
const basemainnet = {
  chainId: 8453,
  name: "Base Blockchain",
  currency: "BNB",
  explorerUrl: "https://basescan.org/",
  rpcUrl: "https://mainnet.base.org/",
};
const basetestnet = {
  chainId: 84532,
  name: "Base Sepholia Testnet",
  currency: "ETH",
  explorerUrl: "https://sepolia.basescan.org/",
  rpcUrl: "https://base-sepolia.blockpi.network/v1/rpc/public/",
};

const polygonmainnet = {
  chainId: 137, // 137 in decimal
  name: 'Polygon Mainnet',
  currency: 'MATIC',
  explorerUrl: "https://polygonscan.com/",
  rpcUrl: "https://polygon-rpc.com/",
};
const polygontestnet = {
  chainId: 80002, // 137 in decimal
  name: 'Polygon Amoi',
  currency: 'MATIC',
  explorerUrl: "https://amoy.polygonscan.com/",
  rpcUrl: "https://rpc-amoy.polygon.technology/",
};
const arbitrumtestnet = {
  chainId: 421614, // 137 in decimal
  name: 'Arbitrum Sepholia Testnet',
  currency: 'ETH',
  explorerUrl: "https://sepolia.arbiscan.io/",
  rpcUrl: "https://endpoints.omniatech.io/v1/arbitrum/sepolia/public/",
};

const tsctestnet = {
  chainId: 820522,
  name: "TSC Testnet",
  currency: "tTAS",
  explorerUrl: "https://testnet.tscscan.io",
  rpcUrl: "https://testnet.tscscan.io/testrpc",
};

const tscmainnet = {
  chainId: 345,
  name: "TSC Mainnet",
  currency: "TAS",
  explorerUrl: "https://www.tscscan.io",
  rpcUrl: "https://rpc01.trias.one",
};

// 3. Create modal
const metadata = {
  name: "Dexplays",
  description: "Dexplays",
  url: "https://mywebsite.com",
  icons: ["https://avatars.mywebsite.com/"],
};

try {
createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [bsctestnet, bscmainnet, basetestnet, basemainnet, polygonmainnet, polygontestnet, arbitrumtestnet, tsctestnet, tscmainnet],
  projectId,
});
} catch (error) {
 alert("Cannot connect")
}

function App() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/app" && !prop.collapse) {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      if (prop.layout === "/admin" && !prop.collapse) {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      if (prop.layout === "/admin" && prop.collapse) {
        return (
          <Route
            path={prop.path + "/*"}
            element={prop.component}
            key={key}
          >
            <Route path="*" element={<Navigate to={prop.layout + "/" + prop.path + "/index"} replace />} />
            {getRoutes(prop.items)}
          </Route>
        );
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  const WebRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/web/index" replace />} />
        <Route path="/web/*" element={<WebLayout />}>
          <Route path="index" element={<Home />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="aml" element={<Aml />} />
          <Route path="*" element={<Navigate to="/web/index" replace />} />
        </Route>
      </Routes>
    );
  };

  const AuthRoutes = () => {
    return (
      <Routes>
        <Route path="/auth/*" element={<WebAuthLayout />}>
          <Route path="index" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<Navigate to="/auth/index" replace />} />
        </Route>
      </Routes>
    );
  };

  const UserRoutes = () => {
    const dispatch = useDispatch();
    const toast = useToast();
    const { open, onConnect } = useWeb3Modal();
    const { web3Modal, address, chainId, isConnected } = useWeb3ModalAccount();
    const { switchNetwork } = useSwitchNetwork()
    const { disconnect, onDisconnect } = useDisconnect();
    const { walletProvider } = useWeb3ModalProvider();
    const user = useSelector((state) => state?.user?.value);
    const isLoggedIn = user.hasOwnProperty("token") ? true : false;

    useLastActive(user.token);

    const [loginLoading, setLoginLoading] = useState(false);

    const handleConnect = async (chainId) => {
      localStorage.setItem("chainId", chainId);
      try {
        await open({
          networkId: chainId,
        });
      } catch (error) {
        console.error("Failed to connect and switch network", error);
      }
    };

    const handleSwitchNetwork = async (chainId) => {
      try {
        const networkParams = connections[chainId];
        if (isConnected && !networkParams) {
          console.log('Network parameters not found for chainId:', chainId);
          return;
        }
        await walletProvider.request({
          method: 'wallet_addEthereumChain',
          params: [networkParams],
        });
        //await switchNetwork(chainId);
      } catch (error) {
        console.error("Failed to switch network", error);
      }
    };

    const handleLogout = async () => {
      dispatch(setUser({}));
      await disconnect();
    };

    const handleLogin = async (address) => {
      if (isLoggedIn) return false;
      try {
        setLoginLoading(true);
        const response = await UserLogin(address);
        setLoginLoading(false);
        if (response.error) {
          toast({
            title: response.error || "An error occured",
            status: "error",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          handleLogout();
          return;
        }
        if (!response.success) {
          toast({
            title: response.message || "An error occured",
            status: "error",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          handleLogout();
          return;
        }
        if (response.success) {
          if (address !== undefined && address.toLowerCase() !== response.data.address.toLowerCase()) {
            toast({
              title: "Invalid login",
              status: "error",
              duration: 9000,
              position: "top-right",
              isClosable: true,
            });
            return;
          }
          dispatch(setUser(response.data));
        }
      } catch (error) {
        setLoginLoading(false);
        toast({
          title: error.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        handleLogout();
      }
    };


    useEffect(() => {
      const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);
      const connectWebSocket = () => {
        socket.onopen = () => {
          socket.send(JSON.stringify({
            type: 'REGISTER',
            payload: {
              clientId: address !== undefined ? address.toLowerCase() : "",
            },
          }));
        };
        socket.onclose = () => {
          reconnectWebSocket();
        };
    
        socket.onerror = (error) => {
          socket.close(); // Trigger the onclose event to start reconnection
        };
      };
    
      const reconnectWebSocket = () => {
        setTimeout(() => {
          connectWebSocket(); // Attempt to reconnect
        }, 5000); // Reconnect after 5 seconds
      };
    
      const checkConnection = () => {
        if (isConnected) {
          handleLogin(address);
          if (parseInt(chainId) !== parseInt(localStorage.getItem("chainId"))) {
            handleSwitchNetwork(localStorage.getItem("chainId"));
          }
          connectWebSocket();
        } else {
          handleLogout();
        }
      };    
      // Adding a timeout to ensure the state is fully updated
      const timeoutId = setTimeout(checkConnection, 700);
      return () => clearTimeout(timeoutId);
    }, [isConnected]);

    
    return (
      <Routes>
        <Route path="/app/*" element={<UserLayout handleLogin={handleLogin} handleLogout={handleLogout} loginLoading={loginLoading} handleConnect={handleConnect} isConnected={isConnected} address={address} />}>
          {getRoutes(routesuser)}
          {/*<Route path="match/:matchId/:teamId" element={<Match />} />*/}
          <Route path="ads/index" element={<BuyOffers />} />
          <Route path="ads/selloffers" element={<SellOffers />} />
          <Route path="ads/user" element={<Ads />} />
          <Route path="ads/edit/:type/:adId" element={<UpdateAd />} />
          <Route path="ads/selloffer/:adId" element={<SellOffer />} />
          <Route path="ads/buyoffer/:adId" element={<BuyOffer />} />
          <Route path="trades/:type/:tradeId" element={<Trade />} />
          <Route path="*" element={<Navigate to="/app/ads" replace />} />
          <Route path="profile" element={<Profile />} />
          <Route path="profile/payment" element={<Payment />} />
          <Route path="profile/:code" element={<Profile />} />
          <Route path="user/:username" element={<User />} />
          <Route path="staking" element={<Staking />} />
          <Route path="faucet" element={<Faucet />} />
        </Route>
        <Route path="/admin/*" element={<AdminLayout />}>
          {getRoutes(routes)}
        </Route>
      </Routes>
    );
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <WebRoutes />
          <AuthRoutes />
          <UserRoutes />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
