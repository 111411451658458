import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Skeleton,
  SkeletonText,
  Select,
  useToast,
  useDisclosure,
  SimpleGrid
} from "@chakra-ui/react";
import { GetPaymentMethods } from "services/miscService";
import { GetUserPaymentMethods, CreateUserPaymentMethod, UpdateUserPaymentMethod } from "services/userService";
import { useSelector } from "react-redux";

export default function Payment() {
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();
  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [createPaymentLoading, setCreatePaymentLoading] = useState(false);
  const [methodId, setMethodId] = useState(null);


  const [paymentMethods, setPaymentMethods] = useState(null);
  const [userPaymentMethods, setUserPaymentMethods] = useState(null);

  const [currentMethod, setCurrentMethod] = useState(null);
  const [currentMethodDetails, setCurrentMethodDetails] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeletePaymentMethod = async () => {
    try {
      const paymentData = { deleted: true };
      setDeleteLoading(true);
      const response = await UpdateUserPaymentMethod(paymentData, methodId, token);
      setDeleteLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onDeleteClose();
        getUserPaymentMethods();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setDeleteLoading(false);
    }
  };

  const [paymentData, setPaymentData] = useState({});
  const handleChange = (e, label) => {
    setPaymentData({
      ...paymentData,
      [label]: e.target.value,
    });
  };

  const handleCreatePaymentMethod = async () => {
    try {
      setCreatePaymentLoading(true);
      const userPaymentData = { ...paymentData, paymentMethod: currentMethod?._id }
      const response = await CreateUserPaymentMethod(userPaymentData, token);
      setCreatePaymentLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onCreateClose();
        getUserPaymentMethods();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setCreatePaymentLoading(false);
    }
  };

  const getPaymentMethods = async () => {
    try {
      setLoading(true);
      const response = await GetPaymentMethods();
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setPaymentMethods(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };


  const handleSelectChange = (e) => {
    const selectedMethod = paymentMethods.find(
      (method) => method._id === e.target.value
    );
    setCurrentMethod(selectedMethod);
    setCurrentMethodDetails(JSON.parse(selectedMethod.details));
  };


  const getUserPaymentMethods = async () => {
    try {
      setLoading(true);
      const response = await GetUserPaymentMethods(token);
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setUserPaymentMethods(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token !== null) {
      getPaymentMethods();
      getUserPaymentMethods();
    } else {
      setUserPaymentMethods(null);
      setPaymentMethods(null);
    }
  }, [token]);

  return (
    <>
      {/* Create Payment Method Modal */}
      <Modal isOpen={isCreateOpen} onClose={onCreateClose} size="lg">
        <ModalOverlay />
        <ModalContent background="brand.500">
          <ModalHeader color="white">Create Payment Method</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <FormControl>
              <FormLabel color="white" fontWeight="bold">
                Payment Option
              </FormLabel>
              <Select
                color="#ffffff"
                placeholder="Select Payment Option"
                onChange={handleSelectChange}
              >
                {paymentMethods !== null && paymentMethods.map((method) => (
                  <option key={method._id} value={method._id}>{method.name}</option>
                ))}
              </Select>
            </FormControl>

            {currentMethodDetails !== null && currentMethodDetails.map((detail) => (
              <FormControl key={detail.label} mb={2} mt="4">
                <FormLabel color="white" fontWeight="bold">
                  {detail.label}
                </FormLabel>
                <Input
                  color="white"
                  maxLength={100}
                  type={detail.type === 'number' ? 'number' : 'text'}
                  placeholder={detail.instruction}
                  onChange={(e) => handleChange(e, detail.label)}
                />
              </FormControl>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onCreateClose}>
              Cancel
            </Button>
            <Button
              background="secondary.500"
              color="white"
              _hover={{ color: "#000000", background: "#ffffff" }}
              isLoading={createPaymentLoading}
              loadingText="Wait.."
              onClick={handleCreatePaymentMethod}
            >
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose} size="sm">
        <ModalOverlay />
        <ModalContent background="brand.500">
          <ModalHeader color="white">Confirm Delete</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody color="white">
            Are you sure you want to delete this payment method?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onDeleteClose}>
              Cancel
            </Button>
            <Button colorScheme="red" isLoading={deleteLoading} isLoadingText="Wait.." onClick={handleDeletePaymentMethod}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box>

        <Box width="100%" textAlign="right">
          <Button
            isLoading={loading}
            loadingText="Please Wait"
            onClick={onCreateOpen}
          >
            Add Payment Method
          </Button>
        </Box>

        {
          loading ? <LoadingPaymentMethods /> :
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={4} mt="5">
              {userPaymentMethods !== null && userPaymentMethods.map(method => (
                <PaymentMethod method={method} key={method._id} formData={formData} onDeleteOpen={onDeleteOpen} setMethodId={setMethodId} />
              ))}
            </SimpleGrid>
        }
      </Box>
    </>
  );
};

const PaymentMethod = ({ method, onDeleteOpen, setMethodId, formData }) => {
  const details = JSON.parse(method?.details);
  return (
    <>
      <Box p={4} borderRadius="md" className="trade-record-card" background="brandScheme.500">
        <Box justifyContent="space-between">
          <Box>
            <Text fontWeight="bold" color="white" fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}>
              {method?.paymentMethod?.name}
            </Text>
            {
              Object.entries(details).map(([key, index]) => (<Flex gap="2" ><Text color="gray.300" fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}> {key}</Text> <Text fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}>{details[key]}</Text></Flex>))
            }
          </Box>
          <Box mt="2" textAlign="right">
            <Button size="sm" colorScheme="red" onClick={() => { setMethodId(method._id); onDeleteOpen(); }}>
              Delete
            </Button>
          </Box>
        </Box>
      </Box>
    </>)
}


const LoadingPaymentMethods = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="10"
        mb="20"
        columns={{ sm: "1", md: "3", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};
