import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  Box,
  Circle,
  Icon,
  Select,
  FormLabel,
  FormControl,
  Input,
  Text,
  Button,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { UpdateUserById, CreateUser, GetUserByID } from "services/userService";
import { GetRoles } from "services/roleService";
import { FiArrowLeft } from "react-icons/fi";

export default function AddUser() {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location.state?.userId;
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const priviledges = userState.hasOwnProperty("priviledges") ? userState.priviledges : [];
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    middleName: "",
    role: null,
  });
  const [roles, setRoles] = useState([]);

  const handleAddUser = async () => {
    try {
      setLoading(true);
      /*if(!priviledges.includes("Manage User")){
        toast({
          title: "You are not allowed",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }*/

      const response =
        userId !== null && userId !== undefined
          ? await UpdateUserById(user, userId, token)
          : await CreateUser(user, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      navigate("/admin/users");
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getUserByID = async () => {
    try {
      setLoading(true);
      const response = await GetUserByID(userId, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setUser({
          email: response.data.email,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          middleName: response.data.middleName,
          role: response.data.role,
        });
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getRoles = async () => {
    try {
      setLoading(true);
      const response = await GetRoles(token);
      setLoading(false);
      if (response.success) {
        setRoles(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [variable, setVariable] = useState("");
  const handleSetValue = (label, value) => {
    user[label] = value;
    setUser(user);
    setVariable(label);
  };

  useEffect(() => {
    setUser(user);
    getRoles();
    if (userId !== null && userId !== undefined) {
      getUserByID();
    }
  }, []);

  useEffect(() => {}, [variable]);

  return (
    <>
      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%" mb="50" mt="20">

      <Box width="100%">
        <Link to="/admin/users/index">
          <Circle width="30px" height="30px" background="brand.400">
            <Icon
              as={FiArrowLeft}
              width="20px"
              height="20px"
              color="#fff"
            />
          </Circle>
        </Link>
      </Box>


        <SimpleGrid
          width="100%"
          columns={{ base: 2, md: 2 }}
          spacing="2"
          mt="4"
          mb="15"
        >
          <Box mb="5">
            {" "}
            <Text
              color={"white"}
              fontSize="18px"
              mb="4px"
              fontWeight="600"
              lineHeight="100%"
            >
              {userId !== null && userId !== undefined
                ? "Update Member"
                : "Add Member"}
            </Text>
          </Box>

          <Box width="100%" textAlign="right">
           
            <Button
              colorScheme="teal"
              background="secondary.500"
              size="sm"
              onClick={() => {
                navigate("/admin/users");
              }}
            >
             Users
            </Button>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="2"
          mb="15"
        >
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">First Name</FormLabel>
              <Input
                 h="10"
                borderRadius="0"
                color="white"
                placeholder="First Name"
                defaultValue={user.firstName}
                onChange={(e) => {
                  setUser({ ...user, firstName: e.target.value });
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Last Name</FormLabel>
              <Input
                h="10"
                color="white"
                borderRadius="0"
                placeholder="First Name"
                defaultValue={user.lastName}
                onChange={(e) => {
                  setUser({ ...user, lastName: e.target.value });
                }}
              />
            </FormControl>
          </Box>
        </SimpleGrid>


        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="2"
          mb="15"
        >
          <Box>
            <FormControl>
              <FormLabel fontWeight="bold">Email</FormLabel>
              <Input
                 h="10"
                color="white"
                borderRadius="0"
                placeholder="email"
                defaultValue={user.email}
                onChange={(e) => {
                  setUser({ ...user, email: e.target.value });
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl>
              <FormLabel fontWeight="bold">Role</FormLabel>
              <Select
                color="white"
                borderRadius="0"
                placeholder="Select Option"
                value={user.role}
                onChange={(e) => {
                  setUser({ ...user, role: e.target.value });
                }}
              >
                {
                  roles.length > 0 &&
                  roles.map((role) => (
                    <option key={role._id} value={role._id}>
                      {role.roleName}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </SimpleGrid>


        <Box textAlign="left" mt="3" width="100%">
          <Button
            width="150px"
            height="30px"
            colorScheme="secondary"
            isLoading={loading}
            loadingText="Wait..."
            onClick={handleAddUser}
          >
            {userId !== null && userId !== undefined ? "Update" : "Create"}
          </Button>
        </Box>
      </Box>
    </>
  );
}
