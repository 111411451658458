import { ethers, BrowserProvider } from "ethers";
import axiosInstance from './axiosInstance'; // Adjust the path accordingly
import FAUCETABI from "contracts/Faucet.json";
import NETWORKS from "contracts/Networks.json";
import USDTABI from "contracts/USDTToken.json";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ContractGetTokens = async (asset, walletConnect) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const { chainId } = walletConnect;
    const FAUCET_CONTRACT = NETWORKS[chainId]["FAUCET_CONTRACT"];
    //createAdvert (string memory _advertId,uint _amount, address _asset)
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const FaucetContract = new ethers.Contract(
      FAUCET_CONTRACT,
      FAUCETABI.abi,
      signer
    );
    
    const transaction = await FaucetContract.withdraw(asset);
    await transaction.wait();
    return true;
   } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed: ${errorMessage}`);
  }
}

const CheckFaucetWithdrawal = async (faucetData, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/faucet/checkWithdrawal", faucetData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

const Withdraw = async (faucetData, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/faucet",
      faucetData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

const ContractFaucetContracts = async (walletConnect) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const { chainId } = walletConnect;
    const FAUCET_CONTRACT = NETWORKS[chainId]["FAUCET_CONTRACT"];
    //createAdvert (string memory _advertId,uint _amount, address _asset)
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const FaucetContract = new ethers.Contract(
      FAUCET_CONTRACT,
      FAUCETABI.abi,
      signer
    );
    
    return await FaucetContract.getTokenInfo();
   } catch (error) {
    if (
      error.hasOwnProperty("data") &&
      error.hasOwnProperty("data") &&
      error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.data.message);
    } 
    else if (
      error.hasOwnProperty("error") &&
      error.error.hasOwnProperty("data") &&
      error.error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.error.data.message);
    } 
    else {
      throw new Error(error.message);
    }
  }
}

const ContractFaucetContractToken = async (tokenContract, walletConnect) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const { chainId } = walletConnect;
    //createAdvert (string memory _advertId,uint _amount, address _asset)
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const TokenContract = new ethers.Contract(
      tokenContract,
      USDTABI.abi,
      signer
    );
    const [name, symbol, contractAddress, decimals] = await TokenContract.getTokenInfo();
    return { name, symbol, contractAddress };
   } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed: ${errorMessage}`);
  }
}

export {
  ContractGetTokens,
  ContractFaucetContractToken,
  CheckFaucetWithdrawal,
  ContractFaucetContracts,
  Withdraw
};
