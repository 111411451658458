import { ethers, BrowserProvider } from "ethers";
import axiosInstance from './axiosInstance'; // Adjust the path accordingly
import SELLADABI from "../contracts/Advert.json";
import BUYADABI from "../contracts/BuyAdvert.json";
import TOKENABI from "../contracts/IBEP20.json";
import NETWORKS from "../contracts/Networks.json";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const CreateBuyAdvert = async (buyAdData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/ad/buy/create", buyAdData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CreateSellAdvert = async (sellAdData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/ad/sell/create", sellAdData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const ContractCreateSellAdvert = async (walletConnect, sellAdData) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const { chainId } = walletConnect;
    const ASSET_CONTRACT = NETWORKS[chainId][sellAdData.symbol];
    const SELL_AD_CONTRACT = NETWORKS[chainId]["SELL_AD_CONTRACT"];

    //createAdvert (string memory _advertId,uint _amount, address _asset)
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const SellAdContract = new ethers.Contract(
      SELL_AD_CONTRACT,
      SELLADABI.abi,
      signer
    );

    const Token = new ethers.Contract(ASSET_CONTRACT, TOKENABI.abi, signer);
    let wei = ethers.parseEther(String(sellAdData.amount));
    let transaction = await Token.approve(SELL_AD_CONTRACT, wei);
    await transaction.wait();

    // Set a timeout in case the transaction takes too long
    const TIMEOUT_MS = 90000; // 1 minute
    let timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
    );
    let receipt = await Promise.race([transaction.wait(), timeoutPromise]);
    if (!receipt && receipt.status !== 1) {
      throw new Error("Transaction failed");
    } 

    transaction = await SellAdContract.createAdvert(sellAdData.advertID, wei, ASSET_CONTRACT);
    // Set a timeout in case the transaction takes too long
    timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
    );
    receipt = await Promise.race([transaction.wait(), timeoutPromise]);
    if (receipt && receipt.status === 1) {
      // Transaction was successful
      const transactionHash = transaction.hash;
      return {
        success: true,
        transactionHash
      };
    } else {
      throw new Error("Transaction failed");
    }

  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed with an unknown error, contact support`);
  }
};

const ContractCreateBuyAdvert = async (walletConnect, buyAdData) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const { chainId } = walletConnect;
    const ASSET_CONTRACT = NETWORKS[chainId][buyAdData.symbol];
    const BUY_AD_CONTRACT = NETWORKS[chainId]["SELL_TRADE_CONTRACT"];

    //createAdvert (string memory _advertId,uint _amount, address _asset)
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const BuyAdContract = new ethers.Contract(
      BUY_AD_CONTRACT,
      BUYADABI.abi,
      signer
    );

    const transaction = await BuyAdContract.createBuyAdvert(buyAdData.advertID, ASSET_CONTRACT);
    // Set a timeout in case the transaction takes too long
    const TIMEOUT_MS = 90000; // 1 minute
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
    );
    const receipt = await Promise.race([transaction.wait(), timeoutPromise]);
    if (receipt && receipt.status === 1) {
      // Transaction was successful
      const transactionHash = transaction.hash;
      return {
        success: true,
        transactionHash
      };
    } else {
      throw new Error("Transaction failed");
    }

  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed with an unknown error, contact support`);
    //throw new Error(`Transaction failed: ${errorMessage}`);
  }
};

const ContractFundSellAdvert = async (walletConnect, fundAdData) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const { chainId } = walletConnect;
    const ASSET_CONTRACT = NETWORKS[chainId][fundAdData.symbol];
    const SELL_AD_CONTRACT = NETWORKS[chainId]["SELL_AD_CONTRACT"];

    //createAdvert (string memory _advertId,uint _amount, address _asset)
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const SellAdContract = new ethers.Contract(
      SELL_AD_CONTRACT,
      SELLADABI.abi,
      signer
    );

    const Token = new ethers.Contract(ASSET_CONTRACT, TOKENABI.abi, signer);
    let wei = ethers.parseEther(String(fundAdData.amount));
    let transaction = await Token.approve(SELL_AD_CONTRACT, wei);

    // Set a timeout in case the transaction takes too long
    const TIMEOUT_MS = 90000; // 1 minute
    let timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
    );
    let receipt = await Promise.race([transaction.wait(), timeoutPromise]);
    if (!receipt && receipt.status !== 1) {
      throw new Error("Transaction failed");
    } 

    transaction = await SellAdContract.deposit(fundAdData.advertID, wei);
    timeoutPromise = new Promise((_, reject) =>
       setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
     );
    receipt = await Promise.race([transaction.wait(), timeoutPromise]);
     if (receipt && receipt.status === 1) {
       // Transaction was successful
       const transactionHash = transaction.hash;
       return {
         success: true,
         transactionHash
       };
     } else {
       throw new Error("Transaction failed");
     }
    
  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }

    throw new Error(`Transaction failed with an unknown error, contact support`);
  }
};

const ContractCloseSellAdvert = async (walletConnect, advertId) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const { chainId } = walletConnect;
    //const ASSET_CONTRACT = NETWORKS[chainId][sellAdData.symbol];
    const SELL_AD_CONTRACT = NETWORKS[chainId]["SELL_AD_CONTRACT"];

    //createAdvert (string memory _advertId,uint _amount, address _asset)
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const SellAdContract = new ethers.Contract(
      SELL_AD_CONTRACT,
      SELLADABI.abi,
      signer
    );

    const transaction = await SellAdContract.closeAdvert(advertId);
     // Set a timeout in case the transaction takes too long
     const TIMEOUT_MS = 90000; // 1 minute
     const timeoutPromise = new Promise((_, reject) =>
       setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
     );
     const receipt = await Promise.race([transaction.wait(), timeoutPromise]);
     if (receipt && receipt.status === 1) {
       // Transaction was successful
       const transactionHash = transaction.hash;
       return {
         success: true,
         transactionHash
       };
     } else {
       throw new Error("Transaction failed");
     }

  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }

    throw new Error(`Transaction failed with an unknown error, contact support`);
  }
};

const ContractCloseBuyAdvert = async (walletConnect, advertId) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const { chainId } = walletConnect;
    //const ASSET_CONTRACT = NETWORKS[chainId][sellAdData.symbol];
    const BUY_AD_CONTRACT = NETWORKS[chainId]["SELL_TRADE_CONTRACT"];

    const signer = await ethersProvider.getSigner();
    // The Contract object
    const BuyAdContract = new ethers.Contract(
      BUY_AD_CONTRACT,
      BUYADABI.abi,
      signer
    );

    const transaction = await BuyAdContract.closeAdvert(advertId);
     // Set a timeout in case the transaction takes too long
     const TIMEOUT_MS = 90000; // 1 minute
     const timeoutPromise = new Promise((_, reject) =>
       setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
     );
     const receipt = await Promise.race([transaction.wait(), timeoutPromise]);
     if (receipt && receipt.status === 1) {
       // Transaction was successful
       const transactionHash = transaction.hash;
       return {
         success: true,
         transactionHash
       };
     } else {
       throw new Error("Transaction failed");
     }
  } catch (error) {
     let errorMessage = "An unexpected error occurred";
     if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed with an unknown error, contact support`);
  }
};

const UpdateSellAdvert = async (sellAdData, adId, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + "/ad/sell/" + adId, sellAdData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateBuyAdvert = async (buyAdData, adId, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + "/ad/buy/" + adId, buyAdData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserSellAd = async (adId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/ad/sell/user/" + adId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetSellAd = async (adId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/ad/sell/" + adId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetBuyAd = async (adId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/ad/buy/" + adId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserBuyAd = async (adId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/ad/buy/user/" + adId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserSellAds = async (userId, filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/ad/sell/userads/"+(userId!==null?userId: ""),
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserBuyAds = async (userId, filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/ad/buy/userads/"+(userId!==null?userId: ""),
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetSellAds = async (filter, pagination, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/ad/sell/all?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetBuyAds = async (filter, pagination, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/ad/buy/all?from="+
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  CreateBuyAdvert,
  CreateSellAdvert,
  ContractCreateSellAdvert,
  ContractCreateBuyAdvert,
  ContractFundSellAdvert,
  ContractCloseSellAdvert,
  ContractCloseBuyAdvert,
  UpdateSellAdvert,
  UpdateBuyAdvert,
  GetUserSellAds,
  GetUserBuyAds,
  GetUserBuyAd,
  GetUserSellAd,
  GetSellAd,
  GetBuyAd,
  GetBuyAds,
  GetSellAds
};
